import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuditLogService {

  getTablesUrl = '/AuditLog/getAuditLogTables';
  getUsersUrl = '/AuditLog/getAuditLogUsers';
  getStatusUrl = '/AuditLog/getAuditLogStatus';
  getAuditLogsUrl = '/AuditLog/getAuditLog';

  constructor(private http: HttpClient,) { }

  getTables() {
    return this.http.get<any>(environment.apiEndpoint + this.getTablesUrl);
  }

  getUsers() {
    return this.http.get<any>(environment.apiEndpoint + this.getUsersUrl);
  }

  getStatus() {
    return this.http.get<any>(environment.apiEndpoint + this.getStatusUrl);
  }

  getAuditLogsData(fromDate: any, toDate: any, table: string, userID: string, status: string,) {
    return this.http.get<any>(environment.apiEndpoint + this.getAuditLogsUrl,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          table: table,
          userID: userID,
          status: status
        }
      });
  }
}
