import { OutagesByDateRange } from '#models/OutagesByDateRange';
import { spGetAuditLog } from '#models/spGetAuditLog';
import { status } from '#models/status';
import { table } from '#models/table';
import { user } from '#models/user';
import { AuditLogService } from '#services/http/audit-log.service';
import { NotificationService } from '#services/notification.service';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.scss'
})

export class AuditLogComponent {
  fromDate?: any;
  toDate?: any;
  tables: table[] = [];
  users: user[] = [];
  statuses: status[] = [];
  selectedTable: string = '';
  selectedUser: string = '';
  selectedStatus: string = '';
  auditLogList: spGetAuditLog[] = [];
  public auditLogDataSource: MatTableDataSource<spGetAuditLog>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OutagesByDateRange>;

  public auditLogsColumns = ['userId', 'log', 'table', 'status', 'key1Name', 'key1Value', 'key2Name', 'key2Value', 'key3Name', 'key3Value', 'key4Name', 'key4Value']

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private auditLogService: AuditLogService,) {
  }

  ngOnInit(): void {
    this.getTables();
    this.getUsers();
    this.getStatus();
  }

  getTables() {
    this.auditLogService.getTables().subscribe((res) => {
      this.tables = res;
    });
  }

  getUsers() {
    this.auditLogService.getUsers().subscribe((res) => {
      this.users = res;
    });
  }

  getStatus() {
    this.auditLogService.getStatus().subscribe((res) => {
      this.statuses = res;
    });
  }

  onTableChange(event: any) {
    this.selectedTable = event.value;
  }

  onUserChange(event: any) {
    this.selectedUser = event.value;
  }

  onStatusChange(event: any) {
    this.selectedStatus = event.value;
  }

  OnFromDateChange(type: string, event: any) {
    if (type == 'From') {
      const startDate = new Date(event.target.value);
      //this.fromDate = this.notifyService.ConvertDateBeforeSave(startDate);

      var date = new Date(startDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      this.fromDate = [date.getFullYear(), mnth, day].join("-");
    }
    if (type == 'To') {
      const endDate = new Date(event.target.value);
      //this.toDate = this.notifyService.ConvertDateBeforeSave(endDate);
      var date = new Date(endDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      this.toDate = [date.getFullYear(), mnth, day].join("-");
    }
  }

  onSubmitClick() {
    if (this.fromDate == undefined || this.fromDate == null) {
      this.notifyService.showError("From date required.", "");
    }
    else if (this.toDate == undefined || this.toDate == null) {
      this.notifyService.showError("To date required.", "");
    }
    else {
      this.auditLogService.getAuditLogsData(this.fromDate, this.toDate, this.selectedTable,
        this.selectedUser, this.selectedStatus,).subscribe((res) => {
          this.auditLogList = res;
          this.auditLogDataSource = new MatTableDataSource<spGetAuditLog>(this.auditLogList);
          //this.auditLogDataSource.paginator = this.paginator;
          setTimeout(() => this.auditLogDataSource.paginator = this.paginator);
          if (this.auditLogList.length == 0) {
            this.notifyService.showError("No records found.", "");
          }
        });
      //});
    }
  }

}
