import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService {

  GetOrganizationsUrl = '/Companies/getOrganizations';
  GetCompaniesUrl = '/OutagesOutageEntry/getCompanies';
  GetCompanyUrl = '/Companies/getCompany';
  GetCompanyUnitsUrl = '/Companies/getCompanyUnits';
  UpdateCopmanyUrl = '/Companies/updateCompany';
  InsertCopmanyUrl = '/Companies/insertCompany';
  DeleteCopmanyUnitUrl = '/UnitInformation/deleteUnitInfo';
  DeleteCompanyUrl = '/Companies/deleteCompany';
  
  constructor(private http: HttpClient,) { }

  getOrganizations(userId: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOrganizationsUrl,
      {
        params: {
          userId: userId
        }
      });
  }

  getCompanies(organizationCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCompaniesUrl,
      {
        params: {
          organizationCode: organizationCode
        }
      });
  }

  getCompany(organizationCode: string, companyCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCompanyUrl,
      {
        params: {
          companyCode: companyCode,
          organizationCode: organizationCode
        }
      });
  }

  getCompanyUnits(organizationCode: string, companyCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCompanyUnitsUrl,
      {
        params: {
          companyCode: companyCode,
          organizationCode: organizationCode
        }
      });
  }

  updateCompany(formData: any) {
    return this.http.post(environment.apiEndpoint + this.UpdateCopmanyUrl, formData,
      {
        params:{
          company: formData
        }
      });
  }

  insertCompany(formData: any){
    return this.http.post(environment.apiEndpoint + this.InsertCopmanyUrl, formData);
  }

  deleteCompanyUnit(unit: any){
    return this.http.post(environment.apiEndpoint + this.DeleteCopmanyUnitUrl, unit);
  }

  deleteCompany(organizationCode: string, companyCode: string, userID: string) {
    let postData: any = {
      companyCode: companyCode,
      organizationCode: organizationCode,
      userID: userID
    };

    return this.http.post(environment.apiEndpoint + this.DeleteCompanyUrl, postData,
      {
        params: {
          company: postData
        }
      });
  }
}
