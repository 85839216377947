<div>
  <div class="nav d-flex justify-content-center">
    <div class="logo d-flex flex-column align-items-center justify-content-center pt-2 ms-auto">
      <img class="hallmark" src="/assets/images/hallmark.png" alt="Chevron Logo" />
      <h1>
        <a class="lh-base light-blue hide-underline" href="/tops/tops-home">Turbine Outages and Parts System</a>
      </h1>
    </div>
    <div class="d-flex align-items-center ms-auto text-center">
      <a class="mx-4" href="/" rel="noopener" style="font-size: 30px; font-weight: bold;">REPT</a>
      <a class="mx-4" href="https://chevron.sharepoint.com/sites/ReptUserGuides/" target="_blank" rel="noopener">
        <img alt="User Guide" src="/assets/images/guide.png" height="35px" width="30px" />
        <span class="icon-text">User Guide</span>
      </a>
    </div>
  </div>
  <!-- <div style="padding-left: 15px;">
    <h2>
      <a class="lh-base light-blue hide-underline" href="/">
        Rotating Equipment Parts Tracker
      </a>
    </h2>
    <h2>
      <a class="lh-base light-blue hide-underline" href="/tops/tops-home">
        Turbine Outages and Parts System
      </a>
    </h2>
  </div> -->
  <div>
    <mat-toolbar color="primary">
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/tops/tops-home">
        Home
      </button>
      <div class="dropdown" style="padding-top: 6px;">
        <button mat-flat-button color="primary" class="dropdown" id="navbar-button1">
          Outages
        </button>
        <div class="dropdown-content">
          <a href="/tops/outages-summary">Outages Summary</a>
          <a href="/tops/outages-details">Outages Detail</a>
          <a href="/tops/outages-entry">Outage Entry</a>
          <a href="/tops/upcoming-outages">Outages by Date Range</a>
        </div>
      </div>
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/tops/parts">
        Parts
      </button>
      <div class="dropdown" style="padding-top: 6px;">
        <button mat-flat-button color="primary" class="dropdown" id="navbar-button2">
          Serial Numbers
        </button>
        <div class="dropdown-content">
          <a href="/tops/SerialNumberMaintenance">Serial Number Maintenance</a>
          <a href="/tops/serial-number-lookup">Serial Number Lookup</a>
        </div>
      </div>
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/tops/items-for-repair">
        Items Out For Repair
      </button>

      <div class="dropdown" style="padding-top: 6px;">
        <button mat-flat-button color="primary" class="dropdown" id="navbar-button">
          Administration
        </button>
        <div class="dropdown-content">
          <a href="/tops/tops-companies">Companies</a>
          <a href="/tops/tops-home">Users</a>
          <a href="/tops/organizations">Organizations</a>
          <a href="/tops/unit-types">Unit Types</a>
          <a href="/tops/components">Components</a>
          <a href="/tops/sections">Sections</a>
          <a href="/tops/audit-log">Audit Log</a>
          <a href="/tops/tops-home">Reports</a>
        </div>
      </div>
    </mat-toolbar>
  </div>
</div>

<div class="container">
  <div class="mat-elevation-z8">
    <table border="0" cellspacing="0" cellpadding="0" width="100%">
      <tbody>
        <tr class="Welcome" style="height:40px">
          <td colspan="3" valign="middle">&nbsp;&nbsp;&nbsp;<span>{{ greetings }}, </span>{{ userName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>