<div style="background-color: white; min-height: 400px;">

    <div class="row" style="font-weight: bold; text-align: center;">
        <span style="font-size: 18px; margin-top: 10px;">Serial Number Report</span>
        <br />
        <span style="font-size: 14px;">Printed On: {{ date | date: 'dd-MMM-yyyy hh:mm:ss a' }}</span>
    </div>

    <table cellpadding="0" cellspacing="0" style="margin-left: 60px; width:92%; margin-top: 10px;">
        <tbody>
            <tr>
                <th valign="bottom">Serial Number</th>
                <th valign="bottom">Part Number</th>
                <th valign="bottom">Starting Hrs</th>
                <th valign="bottom">Start</th>
                <th valign="bottom">End</th>
                <th valign="bottom">Hours</th>
                <th valign="bottom">Lifetime Hours</th>
                <th valign="bottom">Status</th>
            </tr>

            <tr class="Row1" *ngFor="let srNo of serialNumbers let i = index">
                <td valign="top" nowrap="" style="min-width: 150px;">
                    {{ srNo.serial_Number }}<br>
                </td>
                <td valign="top" nowrap="" style="min-width: 150px;">
                    {{ srNo.partNumber }}<br>
                </td>
                <td valign="top" nowrap="">{{ srNo.starting_Hours | number:'1.2-2' }}<br></td>
                <td valign="top" nowrap="" style="max-width: 60px;">
                    {{ srNo.start_Date | date: 'MM/dd/yyyy' }}<br>
                </td>
                <td valign="top" nowrap="" style="max-width: 60px;">
                    {{ srNo.end_Date | date: 'MM/dd/yyyy' }}<br>
                </td>
                <td valign="top" nowrap="">{{ srNo.hours | number:'1.2-2' }}<br></td>
                <td valign="top" nowrap="">{{ srNo.lifeTimeHours | number:'1.2-2' }}<br></td>
                <td valign="top" align="left" style="min-width: 230px;">
                    {{ srNo.statusDescription }}<br>
                </td>
            </tr>

            <tr>
                <td class="endreport" colspan="23" style="border-right:0px none; border-left: 0px none;"><br></td>
            </tr>
        </tbody>
    </table>
</div>