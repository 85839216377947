import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TopsHomeService {
  GetUrl = '/TopsHome/getOutagesData';
  GetItemsForRepairUrl = '/TopsHome/getItemsForRepair';
  GetOutageDataUrl = '/TopsHome/getOutageDetails?id=';
  GetLinksUrl = '/TopsHome/getLinks';
  GetSetsUrl = '/TopsHome/getSets';
  GetUnitInfoUrl = '/TopsHome/getUnitInfoDetails';
  GetUnitOutageHistoryUrl = '/TopsHome/getUnitOutageHistory';

  ////return this.http.get<UserInformation>(url,{params:queryParams});
  constructor(private http: HttpClient,
  ) { }

  public getOutages() {
    return this.http.get<any>(environment.apiEndpoint + this.GetUrl);
  }

  public getItemsForRepair() {
    return this.http.get<any>(environment.apiEndpoint + this.GetItemsForRepairUrl);
  }

  getOutageData(id: number) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageDataUrl + id);
  }

  getLinksData(field1: string, field2: string, field3: string, field4: string, type: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetLinksUrl, { params: { field1: field1, field2: field2, field3: field3, field4: field4, type: type } });
  }

  getSetsData(organizationCode: string, companyCode: string, unit: string, outageDate: any) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSetsUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outageDate: outageDate
        }
      });
  }

  getUnitInfoData(organizationCode: string, companyCode: string, unit: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetUnitInfoUrl,
      {
        params: {
          companyCode: companyCode,
          organizationCode: organizationCode,
          unit: unit
        }
      });
  }

  getUnitOutageHistory(organizationCode: string, frameType: string, companyCode: string, unit: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetUnitOutageHistoryUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          companyCode: companyCode,          
          unit: unit
        }
      });
  }
}
