<div style="overflow-y: hidden !important;" id="my-modal-dialog">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">Outage Information</h1>
    <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">X</button>
  </div>

  <div mat-dialog-content id="my-modal-body">

    <table class="BlackText" cellpadding="4" cellspacing="0" style="width: 80%; margin-top: 1px;">
      <tbody>
        <tr class="Header outage-info" style="text-align: center;">
          <th style="width: 20%;">Organization</th>
          <th>Company</th>
          <th>Unit</th>
          <th>Unit Type</th>
          <th>DLN</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
        <tr style="text-align: center;">
          <td>{{ outageInfo.description }}</td>
          <td>{{ outageInfo.companyName }}</td>
          <td>{{ outageInfo.unit }}</td>
          <td>{{ outageInfo.frameType }}</td>
          <td>{{ outageInfo.dln ? 'Y' : 'N'}}</td>
          <td>{{ outageInfo.outageDate | date: 'MM/dd/yyyy' }}</td>
          <td>{{ outageInfo.outageEndDate | date: 'MM/dd/yyyy' }}</td>
        </tr>
      </tbody>
    </table>

    <table class="BlackText" cellpadding="4" cellspacing="0" style="width: 100%; margin-top: 7px;">
      <tbody>
        <tr class="Header outage-info" style="text-align: center;">
          <th>Type</th>
          <th>Unit Hrs</th>
          <th>Peak Fired Hrs</th>
          <th>Project</th>
          <th>Duration (Days)</th>
          <th>Turnaround Name</th>
        </tr>
        <tr style="text-align: center;">
          <td style="width: 29%;">{{ outageInfo.outageTypeDescription }}</td>
          <td>{{ outageInfo.unitHours }}</td>
          <td>{{ outageInfo.peakFiredHours }}</td>
          <td>{{ outageInfo.projectCode }}</td>
          <td>{{ outageInfo.duration }}</td>
          <td> <span class="BlackText" allownull="true" maxlength="50" readonly="true"
              style="display:inline-block;width:150px;">{{ outageInfo.turnaroundName }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <table cellspacing="40" style="margin-top: 20px;">
      <tbody>
        <tr>
          <td style="width: 50%; padding-left: 50px;">
            <input type="button" class="BlackText" value="Edit" (click)="onEditClick(outageInfo)"
              style="width: 100px; color: black;">
          </td>
          <td>
            <input type="button" class="BlackText" value="Open Active Turnarounds" style="width: 170px; color: black;"
              (click)="openSharepoint()">
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 10px;">
      <div class="filter-bar tab-filter-bar">
        <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

          <mat-tab label="Sets">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table class="BlackText">
                  <tbody>
                    <tr>
                      <td>Legend:&nbsp;&nbsp;</td>
                      <td width="10" style="background-color:Red;"><br></td>
                      <td style="padding-left: 5px;">Max Hours Exceed</td>
                    </tr>
                  </tbody>
                </table>

                <table class="BlackText Border" cellspacing="0" cellpadding="4"
                  style="border-collapse:collapse; width: 90%;">
                  <tbody>
                    <tr class="Header outage-info" style="text-align: center;">
                      <th colspan="2"></th>
                      <th>Part/Set</th>
                      <th colspan="5" style="border-bottom:1px solid white">Part/Set Out Information</th>
                    </tr>
                    <tr class="Header outage-info" style="text-align: center;">
                      <th align="center">Component</th>
                      <th style="width:10px;"><br></th>
                      <th align="center">In</th>
                      <th align="center">Out</th>
                      <th align="center">Life Time Hrs</th>
                      <th align="center">Interval Hrs</th>
                      <th align="center">Scrap</th>
                      <th align="center">Repair</th>
                    </tr>

                    <tr class="SubHeader" *ngIf="compressor.length > 0">
                      <th colspan="8">Compressor</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of compressor let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="combustion.length > 0">
                      <th colspan="8">Combustion</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of combustion let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="turbine.length > 0">
                      <th colspan="8">Turbine</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of turbine let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="generator.length > 0">
                      <th colspan="8">Generator</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of generator let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="gearBox.length > 0">
                      <th colspan="8">Gear Box</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of gearBox let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="engine.length > 0">
                      <th colspan="8">Engine</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of engine let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="gasRemovalSystem.length > 0">
                      <th colspan="8">Gas Removal System</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of gasRemovalSystem let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="coolingSystem.length > 0">
                      <th colspan="8">Cooling System</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of coolingSystem let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="highPressureTurbine.length > 0">
                      <th colspan="8">High Pressure Turbine</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of highPressureTurbine let i = index"
                      style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="lowPressureTurbine.length > 0">
                      <th colspan="8">Low Pressure Turbine</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of lowPressureTurbine let i = index"
                      style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                    <tr class="SubHeader" *ngIf="catalyst.length > 0">
                      <th colspan="8">Catalyst</th>
                    </tr>
                    <tr class="Row1 BlackText" *ngFor="let set of catalyst let i = index" style="color: black;">
                      <td>
                        <span>{{set.componentNameNew}}</span>
                      </td>
                      <td style="width:10px;">
                        <br>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setNumber}}</a>
                      </td>
                      <td align="center">
                        <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                      </td>
                      <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                        set.lifeTimeHours > set.maxHours}">
                        {{ set.lifeTimeHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.intervalHours | number: '1.0-0'}}
                      </td>
                      <td align="center">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td align="center">
                        {{ set.repairDate | date: 'MM/dd/yyyy'}}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Meters">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr class="Row1">
                      <th align="left">Manual Starts:</th>
                      <td align="center">{{ outageInfo.manualStarts }}</td>
                    </tr>
                    <tr class="Row2">
                      <th align="left">Total Starts:</th>
                      <td align="center">{{ outageInfo.totalStarts }}</td>
                    </tr>
                    <tr class="Row1">
                      <th align="left">Fired Starts:</th>
                      <td align="center">{{ outageInfo.firedStarts }}</td>
                    </tr>
                    <tr class="Row2">
                      <th align="left">Emergency Stops:</th>
                      <td align="center">{{ outageInfo.emergencyStops }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="People">
            <ng-template matTabContent>
              <!-- <div style="margin-top: 25px;"> -->
              <table class="BlackText" cellpadding="4" cellspacing="0">
                <tbody>
                  <tr class="Row1">
                    <th align="left">Engineer(s):</th>
                    <td>{{ outageInfo.engineer }}</td>
                  </tr>
                  <tr class="Row2">
                    <th align="left">Plant Supervisor:</th>
                    <td>{{ outageInfo.plantSupervisor }}</td>
                  </tr>
                  <tr class="Row1">
                    <th align="left">Contractor:</th>
                    <td>{{ outageInfo.contractor }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- </div> -->
            </ng-template>
          </mat-tab>

          <mat-tab label="Comments">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <span class="BlackText" *ngIf="outageInfo.comments != null">{{ outageInfo.comments }}</span>
                <span class="BlackText" *ngIf="outageInfo.comments === null">No comments.</span>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Links ({{ links.length}})">
            <ng-template matTabContent>
              <div style="margin-top: 15px;">
                <div *ngIf="links && links.length >0; else noLinks">
                  <table style="border: black;" cellpadding="4" cellspacing="0">
                    <tbody>
                      <tr class="Header outage-info" style="text-align: center;">
                        <th style="color: white; background-color: #009dd0;">Link</th>
                      </tr>
                      <tr style="text-align: center;">
                        <td>
                          <div *ngFor="let linkItem of links">
                            <a [href]="(linkItem.link.indexOf('http://')===0?'':'') +'file://'+ linkItem.dynamicLink + linkItem.link"
                              target="_blank">
                              {{linkItem.description}}</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noLinks>
                  <span class="BlackText">No links found</span>
                </ng-template>

              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

  </div>

  <!-- <div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" class="btn btn-default"> Close </button> &nbsp;&nbsp;
</div> -->

</div>