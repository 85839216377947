import { Component, Output, EventEmitter, Input, SimpleChanges, OnInit, OnChanges } from '@angular/core';
interface ColorOption {
  name: string;
  hex: string;
}
@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})

export class ColorPickerComponent implements OnInit, OnChanges {
  @Input() initialColor: string = '';
  @Output() colorSelected = new EventEmitter<string>();

  colors: ColorOption[] = [
    { name: 'Black', hex: '#000000' },
    { name: 'Burnt Orange', hex: '#993300' },
    { name: 'Dark Olive', hex: '#333300' },
    { name: 'Dark Green', hex: '#003300' },
    { name: 'Dark Teal', hex: '#003366' },
    { name: 'Navy', hex: '#000080' },
    { name: 'Slate Blue', hex: '#333399' },
    { name: 'Dark Gray', hex: '#333333' },
    { name: 'Maroon', hex: '#800000' },
    { name: 'Orange', hex: '#FF6600' },
    { name: 'Olive', hex: '#808000' },
    { name: 'Green', hex: '#008000' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Blue', hex: '#0000FF' },
    { name: 'Light Slate Blue', hex: '#666699' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Red', hex: '#FF0000' },
    { name: 'Bright Orange', hex: '#FF9900' },
    { name: 'Lime Green', hex: '#99CC00' },
    { name: 'Sea Green', hex: '#339966' },
    { name: 'Aqua', hex: '#33CCCC' },
    { name: 'Royal Blue', hex: '#3366FF' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Light Gray', hex: '#999999' },
    { name: 'Magenta', hex: '#FF00FF' },
    { name: 'Gold', hex: '#FFCC00' },
    { name: 'Yellow', hex: '#FFFF00' },
    { name: 'Lime', hex: '#00FF00' },
    { name: 'Cyan', hex: '#00FFFF' },
    { name: 'Sky Blue', hex: '#00CCFF' },
    { name: 'Plum', hex: '#993366' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Light Pink', hex: '#FF99CC' },
    { name: 'Peach', hex: '#FFCC99' },
    { name: 'Light Yellow', hex: '#FFFF99' },
    { name: 'Light Green', hex: '#CCFFCC' },
    { name: 'Light Cyan', hex: '#CCFFFF' },
    { name: 'Light Blue', hex: '#99CCFF' },
    { name: 'Lavender', hex: '#CC99FF' },
    { name: 'White', hex: '#FFFFFF' }
  ];
  
  

  selectedColor: ColorOption = { name: '', hex: '' };

  ngOnInit() {
    this.setSelectedColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialColor']) {
      this.setSelectedColor();
    }
  }

  setSelectedColor() {
    this.selectedColor = this.colors.find(color => color.hex === this.initialColor) || { name: '', hex: '' };
  }

  selectColor(color: ColorOption) {
    this.selectedColor = color;
    this.colorSelected.emit(this.selectedColor.hex); // Emit hex code instead of name
  }
}
