<div class="container">
  <form [formGroup]="companyForm" (ngSubmit)="onSave()">

    <div class="row" *ngIf="errorList.length > 0">
      <div>
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let item of errorList">
            <div class="error-list"><mat-icon class="material-symbols-rounded">warning</mat-icon> {{item}}</div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 companies-left-panel">
        <div class="row">
          <mat-card>
            <mat-card-content>
              <div class="companies-left-header">
                <span>Organization</span>
              </div>
              <mat-form-field appearance="outline" class="organization-select">
                <mat-label>select organization</mat-label>
                <mat-select #organizationSelect [(ngModel)]="SelectedOrganization" (selectionChange)="getCompanies()"
                  formControlName="organization" required>
                  <mat-option *ngFor="let organization of organizations" [value]="organization">
                    {{ organization.description }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError(null,'organization', 'required')">
                  Please select a Organization.
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="row">
          <mat-card>
            <mat-card-content>
              <div class="companies-left-header">
                <span>Company</span>
              </div>
              <mat-action-list>
                <button type="button" mat-list-item *ngFor="let company of companies" (click)="getCompany(company.companyCode)">{{company.companyName}}</button>
              </mat-action-list>
              <div>
                <button type="button" mat-button class="button-add" (click)="newCompany()">New Company</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="col">

        <div class="row" *ngIf="isUpdate != null && !isUpdate" formGroupName="newForm">
          <div class="col company-details">
            <mat-card>
              <mat-card-content>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Code:</mat-label>
                  </div>
                  <div class="col">
                    <input #input maxlength="3" placeholder="Ex. AAA" class="shortInput" [(ngModel)]="NewCompany.companyCode" formControlName="new_companyCode">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Short Name:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="50" class="mediumInput" [(ngModel)]="NewCompany.companyName" formControlName="new_shortName">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Full Name:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="100" class="longInput" [(ngModel)]="NewCompany.fullName" formControlName="new_fullName">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI Server:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="100" class="longInput" [(ngModel)]="NewCompany.pIServer" formControlName="new_piServer">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI User:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="50" class="mediumInput" [(ngModel)]="NewCompany.pIUser" formControlName="new_piUser"> 
                    *Should be read only account
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI Password:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput type="password" placeholder="" class="mediumInput" [(ngModel)]="NewCompany.pIPassword" formControlName="new_pIPassword"/>
                  </div>
                </div>
                <div class="row fields">
                  <div class="col">
                    <button mat-flat-button color="primary" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="row" *ngIf="isUpdate != null && isUpdate" formGroupName="updateForm">
          <div class="col company-details">
            <mat-card>
              <mat-card-content>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Code:</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="3" class="shortInput" placeholder="Ex. AAA" disabled [(ngModel)]="SelectedCompany.companyCode" formControlName="companyCode">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Organization</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="3" class="shortInput" placeholder="Ex. AAA" disabled [(ngModel)]="SelectedOrganization.description" formControlName="organizationCode">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Name</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="50" class="mediumInput" [(ngModel)]="SelectedCompany.companyName" formControlName="shortName">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>Full Name</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="100" class="longInput" [(ngModel)]="SelectedCompany.fullName" formControlName="fullName">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI Server</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="100" class="longInput" [(ngModel)]="SelectedCompany.pIServer" formControlName="piServer">
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI User</mat-label>
                  </div>
                  <div class="col">
                    <input matInput #input maxlength="50" class="mediumInput" [(ngModel)]="SelectedCompany.pIUser" formControlName="piUser">
                    *Should be read only account
                  </div>
                </div>
                <div class="row fields">
                  <div class="col-sm-2">
                    <mat-label>PI Password</mat-label>
                  </div>
                  <div class="col">
                    <input matInput type="password" class="mediumInput" [(ngModel)]="SelectedCompany.pIPassword" formControlName="pIPassword"/>
                  </div>
                </div>
                <div class="row fields">
                  <div class="col">
                    <button type="button" mat-flat-button color="primary" type="submit">
                      Update
                    </button>
                    <button type="button" mat-flat-button color="primary" (click)="ShowUnitNewDialog()">
                      New Unit
                    </button>
                    <button type="button" mat-flat-button color="primary" [hidden]="(SelectedCompany.inUse != 0 ? true : false)" (click)="deleteCompany()">
                      Delete
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="row" *ngIf="isUpdate != null && isUpdate">
          <div class="col company-details">
            <mat-card>
              <mat-card-content>
                <div class="row">
                  <table mat-table [dataSource]="companyUnits" class="company-table" matSort>
          
                    <ng-container matColumnDef="unit">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Unit" class="company-cell"> Unit </th>
                      <td mat-cell *matCellDef="let element" class="BlackText"> {{element.unit}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="unit-type">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Unit Type" class="company-cell"> Unit Type </th>
                      <td mat-cell *matCellDef="let element" class="BlackText"> {{element.frameTypeDescription}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="fuel-source">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Fuel Source" class="company-cell"> Fuel Source </th>
                      <td mat-cell *matCellDef="let element" class="BlackText"> {{element.fuelSource}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="dln">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DLN" class="company-cell"> DLN </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-checkbox [checked]="element.dln" [disabled]="true">
                        </mat-checkbox> 
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="peaker">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Peaker" class="company-cell"> Peaker </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-checkbox [checked]="element.peaker" [disabled]="true">
                        </mat-checkbox>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="projected-outage-color">
                      <th mat-header-cell *matHeaderCellDef class="company-cell"> Projected Outage Color </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="myStyle(element.color)"></td>
                    </ng-container>
                    
                    <ng-container matColumnDef="up-time">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Up Time" class="company-cell"> % Up Time </th>
                      <td mat-cell *matCellDef="let element" class="BlackText"> {{element.percentUpTime}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="firing-temp">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Firing Temp" class="company-cell"> Firing Temp </th>
                      <td mat-cell *matCellDef="let element" class="BlackText"> {{element.firingTemperature}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="company-cell"> action </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="action-button-box">
                          <span>                            
                            <button type="button" mat-button class="button-delete" 
                                    (click)="confirmDeleteUnit(SelectedOrganization.code, SelectedCompany.companyCode, element.unit)">
                              delete
                            </button>
                          </span>
                          <span>
                            <button type="button" mat-button class="button-edit" (click)="ShowUnitEditDialog(element)">edit</button>
                          </span>
                        </div>
                      </td>
                    </ng-container>
          
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="color: white; height: 20px;" class="header-color"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 40px !important;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No data</td>
                    </tr>
                  </table>
          
                  <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
                    [pageSizeOptions]="[15, 30, 50, 100, 200]" aria-label="Select page of units">
                  </mat-paginator>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>