import { TopsHomeService } from '#services/http/tops-home.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { vOutage } from '#models/VOutage';
import { Link } from '#models/Link';
import { spGetOutageSets } from '#models/spGetOutageSets';

@Component({
  selector: 'app-outage-dialog',
  templateUrl: './outage-dialog.component.html',
  styleUrls: ['./outage-dialog.component.scss']
})

export class OutageDialogComponent {

  outageInfo = new vOutage();
  links = new Link();
  linkStr: string = '';

  // // Set Types
  outageSets: spGetOutageSets[] = [];
  compressor: spGetOutageSets[] = [];
  combustion: spGetOutageSets[] = [];
  turbine: spGetOutageSets[] = [];
  generator: spGetOutageSets[] = [];
  gearBox: spGetOutageSets[] = [];
  engine: spGetOutageSets[] = [];
  gasRemovalSystem: spGetOutageSets[] = [];
  coolingSystem: spGetOutageSets[] = [];
  highPressureTurbine: spGetOutageSets[] = [];
  lowPressureTurbine: spGetOutageSets[] = [];
  catalyst: spGetOutageSets[] = [];

  constructor(public dialogRef: MatDialogRef<OutageDialogModel>,
    private router: Router, private topsHomeService: TopsHomeService,
    private datePipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.topsHomeService.getOutageData(this.data.id).subscribe((res) => {
      this.outageInfo = res;
      const outageDtStr = this.datePipe.transform(this.outageInfo.outageDate, 'MM/dd/yyy');

      this.topsHomeService.getLinksData(this.outageInfo.organizationCode, this.outageInfo.companyCode,
        this.outageInfo.frameType, outageDtStr, 'O').subscribe((res: Link) => {
          this.links = res;
          this.linkStr = this.links.dynamicLink;
        });

      // // Get Sets Data
      this.topsHomeService.getSetsData(this.outageInfo.organizationCode, this.outageInfo.companyCode,
        this.outageInfo.unit, this.outageInfo.outageDate).subscribe((res: spGetOutageSets[]) => {
          this.outageSets = res;

          this.compressor = this.outageSets.filter(x => x.sectionNew == 1);
          this.combustion = this.outageSets.filter(x => x.sectionNew == 2);
          this.turbine = this.outageSets.filter(x => x.sectionNew == 3);
          this.generator = this.outageSets.filter(x => x.sectionNew == 4);
          this.gearBox = this.outageSets.filter(x => x.sectionNew == 5);
          this.engine = this.outageSets.filter(x => x.sectionNew == 6);
          this.gasRemovalSystem = this.outageSets.filter(x => x.sectionNew == 7);
          this.coolingSystem = this.outageSets.filter(x => x.sectionNew == 8);
          this.highPressureTurbine = this.outageSets.filter(x => x.sectionNew == 9);
          this.lowPressureTurbine = this.outageSets.filter(x => x.sectionNew == 10);
          this.catalyst = this.outageSets.filter(x => x.sectionNew == 11);
        });

    });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  openSharepoint() {
    window.open("https://chevron.sharepoint.com/sites/cgpmpe/SitePages/Active-Turnarounds-and-Gas-Turbine-Lifecycle.aspx", '_blank');
  }

  public onEditClick(info: vOutage): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/outage-entry'], {
        queryParams: {
          OrgCode: info.organizationCode,
          CompCode: info.companyCode,
          FrType: info.frameType,
          unit: info.unit,
          outageDate: info.outageDate,
          id: this.data.id
        },
      })
    );
    //close the dialog before navigating
    this.dialogRef.close();

    this.router.navigateByUrl(url);
  }

  public onSetInOutClick(set: spGetOutageSets) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-entry'], {
        queryParams: {
          OrgCode: set.organizationCodeNew,
          CompCode: set.componentCode,
          FrType: set.frameType,
          SetNo: set.setNumber,
          id: this.data.id
        },
      })
    );

    window.open(url, '_blank');
  }

  maximize() {
    //this.isMaximize = !this.isMaximize;
    document.getElementById('my-modal-dialog').style.maxWidth = "100%";
    document.getElementById('my-modal-dialog').style.margin = "auto";
    document.getElementById('my-modal-dialog').style.display = "inline";
    document.getElementById('my-modal-body').style.maxHeight = "calc(100vh - 139px)";
  }
}

export class OutageDialogModel { }
