import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';


//Table Imports 
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';

// model imports 
import { TopsComponent } from '#models/tops-component';

// service imports 
import { ComponentsService } from '#services/http/components.service';
import { NotificationService } from '#services/notification.service';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {

  public componentDataSet: TopsComponent[]; // dataset to display component list in mat-table 
  public componentTable: MatTableDataSource<TopsComponent>; // what will be plugged into the table 
  public displayedColumns: string[] = ['code', 'description', 'actions']
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 

  // ngModel variables for template driven form 
  public componentCodeInput: string;

  public componentNameInput: string;

  public componentNameUpdate: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TopsComponent>;

  constructor(private componentSvc: ComponentsService, private changeDetectionRef: ChangeDetectorRef, private notificationSvc: NotificationService) {
    // empty on purpose 
  }

  ngOnInit(): void {

    this.componentTable = new MatTableDataSource<TopsComponent>();

    // getting the Tops Components on INIT
    this.componentSvc.getAllComponents().subscribe({
      next:
        (data: TopsComponent[]) => {
          this.componentDataSet = data;
          this.componentTable = new MatTableDataSource<TopsComponent>(this.componentDataSet);
          this.componentTable.paginator = this.paginator;
          this.componentTable.sort = this.sort;
        },
      error: ((error: HttpErrorResponse) => {
        this.notificationSvc.showError("Components could not be loaded, if error persists please submit a ticket", "Component Load Error")
        throw error;
      })
    });
  }


  public onInsertComponentClick() {
    if (this.componentCodeInput !== undefined && this.componentCodeInput !== "" && this.componentNameInput !== undefined && this.componentNameInput !== "") {

      this.componentSvc.insertComponent(this.componentCodeInput.toUpperCase(), this.componentNameInput).subscribe({
        next: (_insertedComponent) => {
          if (_insertedComponent == '-1') {
            let component: TopsComponent = new TopsComponent();
            component.componentCode = this.componentCodeInput.toUpperCase();
            component.componentName = this.componentNameInput;
            component.inUse = 0;

            this.componentTable.data = [...this.componentTable.data, component].sort((first, second) => (first.componentCode > second.componentCode ? 1 : -1));
            console.log('Updated data:', this.componentTable.data);
            //this.changeDetectionRef.detectChanges();
            this.table.renderRows();
            //this.componentTable._updateChangeSubscription();
            this.notificationSvc.showSuccess(`Success! ${component.componentCode} was added.`, "");
            this.onCancelInsertComponentClick(); // clearing the inputs 
          }
          else {
            this.notificationSvc.showError(_insertedComponent.toString(), "");
          }
        },
        error: (error: HttpErrorResponse) => {
          this.notificationSvc.showError("Component could not be inserted, if error persists please submit a ticket", "Component Insert Error");
          throw error;
        }
      });
    }
    else {
      this.notificationSvc.showError("Component Code and Description are required.", "Validation Error");
    }
  }

  public onCancelInsertComponentClick() {
    this.componentCodeInput = '';
    this.componentNameInput = '';
  }

  public isEditing(row: TopsComponent): boolean {
    return this.editState[row.componentCode] || false;
  }

  // click the edit component will display the update and cancel buttons 
  public onEditComponentClick(row: TopsComponent) {
    this.editState[row.componentCode] = true;
    this.componentNameUpdate = row.componentName;
  }
  // clicking insert after clicking edit 
  public onUpdateComponentClick(row: TopsComponent) {

    this.componentNameUpdate.trim(); // "sanitize" input from spaces and new line character

    if (this.componentNameUpdate !== "" || undefined) {
      this.componentSvc.updateComponent(row.componentCode, this.componentNameUpdate).subscribe({
        next:
          (_updateResponse) => {
            this.notificationSvc.showSuccess(`Success! ${row.componentCode} was modified.`, "")
            // once update is successful update the data source to display on the table, note we are using Angular's built in change detection to be more performant 
            const index = this.componentTable.data.findIndex(c => c.componentCode === row.componentCode); // find row in datasource 
            if (index != -1) {
              // udpate data in datasource 
              row.componentName = this.componentNameUpdate;

              this.componentTable.data[index] = row;

              this.changeDetectionRef.detectChanges(); // trigger a check of the component for changes 
            }
            this.editState[row.componentCode] = false;
          },
        error: ((error: HttpErrorResponse) => {
          this.notificationSvc.showError(`${row.componentCode} could not be updated.`, "");
          this.editState[row.componentCode] = false;
          throw error;
        })
      });
    }
    else {
      this.notificationSvc.showError("Component Description cannot be empty.", "Validation Error");
      this.editState[row.componentCode] = true;
    }

  }

  public onCancelEditClick(row: TopsComponent) {

    this.editState[row.componentCode] = false; // show the edit and delete icons again 
  }

  public onDeleteComponentClick(row: TopsComponent) {

    this.editState[row.componentCode] = false;

    this.componentSvc.deleteComponent(row.componentCode).subscribe({
      next:
        (_updateResponse) => {
          this.notificationSvc.showSuccess("Success! The component was deleted.", "");
          // once update is successful update the data source to display on the table, note we are using Angular's built in change detection to be more performant 
          this.componentTable.data = this.componentTable.data.filter(record => record.componentCode !== row.componentCode);
        },
      error: ((error: HttpErrorResponse) => {
        this.notificationSvc.showError("The Component could not be deleted, if the error persists please submit a ticket.", "");
        throw error;
      })
    });
  }

  // function auto used by Angular to uniquely identify each item in the list 
  public trackByFn(index: number, item: TopsComponent): string {
    return item.componentCode;
  }


}
