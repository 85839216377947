import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { TopsComponent } from '#models/tops-component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  constructor(private http: HttpClient) { }

  /***
   * Get ALL components 
   * @param: none 
   */
  public getAllComponents (): Observable<TopsComponent[]> {
    return this.http.get<TopsComponent[]>(`${environment.apiEndpoint}/Components/getComponents`);
  }

  /***
   * Delete specific Component via code 
   * @param: ComponentCode // where this is included in the query 
   */
  public deleteComponent(componentcode:string): Observable<void> {
    const param = {componentcode}
    return this.http.post<void>(`${environment.apiEndpoint}/Components/deleteComponent`, componentcode, {params: param});
  }

  /***
   * Insert NEW component 
   * @param: componentCode
   * @param: componentDescription/Name
   * Note: at this point 10/9/2024 both are required fields and this should be refelcted in form validation 
   */
  public insertComponent(componentCode: string, componentName: string ): Observable<string>{
    const param = {
      componentCode,
      componentName
    }

    const body ={
      componentCode,
      componentName
    }
    return this.http.post<string>(`${environment.apiEndpoint}/Components/insertComponent`,body,  {params: param});
  }

  /***
   *  Update component 
   * @param: componentCode
   * @param: componentDescription/Name
   * Note that in swagger there is a field for UserId in the JSON but API handles that, we don't need to pass it from the frontend
   */
  public updateComponent(componentCode: string, componentName: string): Observable<void>{
    const body = {
      componentCode,
      componentName
    }
    return this.http.post<void>(`${environment.apiEndpoint}/Components/updateComponent`, body);
  }
}
