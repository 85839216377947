import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class OrganizationsService {

  GetOrganizationsUrl = '/Organizations/getOrganizations';
  InsertOrganizationUrl = '/Organizations/insertOrganization';
  UpdateOrganizationUrl = '/Organizations/updateOrganization';
  DeleteOrganizationUrl = '/Organizations/deleteOrganization';

  constructor(private http: HttpClient,) { }

  getOrganizations(userId: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOrganizationsUrl,
      {
        params: {
          userId: userId
        }
      });
  }

  insertOrganization(formData: any, userId: string) {
    return this.http.post(environment.apiEndpoint + this.InsertOrganizationUrl, formData,
      {
        params:{
          org: formData,
          userId: userId
        }
      });
  }

  updateOrganization(formData: any, userId: string) {
    return this.http.post(environment.apiEndpoint + this.UpdateOrganizationUrl, formData,
      {
        params:{
          company: formData,
          userId: userId
        }
      });
  }

  deleteOrganization(formData: any, userId: string) {
    return this.http.post(environment.apiEndpoint + this.DeleteOrganizationUrl, formData,
      {
        params:{
          org: formData,
          userId: userId
        }
      });
  }

}
