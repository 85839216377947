export class spGetCompany {
    companyCode: string;
    organizationCode: string;
    companyName: string;
    color:string;
    fullName: string;
    pIServer: string;
    pIUser: string;
    pIPassword: string;
    fileLocation:string;
    organization:string;
    inUse: number;
}
