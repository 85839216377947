<div class="container" style="background-color: white;">
    <table class="BlackText" cellpadding="4" cellspacing="0" style="margin-top: 10px;">
        <tbody>
            <tr style="margin-top: 10px;">
                <td>Organization:</td>
                <td>
                    <span class="BlackText">{{ partEntryInfo?.organization }}</span>
                </td>
            </tr>
            <tr>
                <td>Unit Type:</td>
                <td>
                    <span class="BlackText">{{ partEntryInfo?.frameTypeDescription }}</span>
                </td>
            </tr>
            <tr>
                <td>Component:</td>
                <td>
                    <span class="BlackText">{{ partEntryInfo?.component_Name }}</span>
                </td>
            </tr>
            <tr>
                <td>{{ partEntryInfo?.kitDescription }} Number:</td>
                <td>
                    <span class="BlackText">{{ partEntryInfo.set_Number }}</span>
                </td>
            </tr>
            <tr>
                <td>Number Of:</td>
                <td>
                    <input class="dxeEditArea dxeEditAreaSys" [(ngModel)]="partEntryInfo.numberOf" type="number"
                        autocomplete="off" style="width: 70px;" (change)="UpdateIntValues(partEntryInfo, 'numberOf')">
                </td>
            </tr>
            <tr>
                <td>DLN:</td>
                <td>{{ partEntryInfo?.dlnDescription }}</td>
            </tr>
            <tr>
                <td>Location:</td>
                <td>
                    <span *ngIf="partEntryInfo?.status !== null">{{ partEntryInfo?.status }}</span>
                    <span *ngIf="partEntryInfo?.status === null">Not In Use</span>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="row col-md-11 tabbed tabbed-content-control centered" style="margin-top: 10px; width: 100%;">
        <div class="filter-bar tab-filter-bar">
            <mat-tab-group [selectedIndex]="tabIndex" animationDuration="0ms" [disableRipple]='true'
                disablepagination="true">

                <mat-tab label="General">
                    <ng-template matTabContent>
                        <div style="margin-top: 15px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Part Life Classification:</td>
                                        <td>
                                            <mat-form-field appearance="outline" style="height: 20px;">
                                                <mat-select [(value)]="selected"
                                                    (selectionChange)="onPartLifeChange(partEntryInfo, $event)">
                                                    <mat-option *ngFor="let row of partLifeClassification"
                                                        [value]="row.id">
                                                        {{ row.description }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Style:</td>
                                        <td>
                                            <input type="text" maxlength="50" [(ngModel)]="partEntryInfo.style"
                                                class="BlackText" size="50" style="margin-top: 10px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Service Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline" style="height: 20px; margin-top: 5px;">
                                                <input matInput [matDatepicker]="picker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="partEntryInfo.service_Date"
                                                    (dateChange)="OnDateChange(partEntryInfo, 'ServiceDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Scrap Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="picker1"
                                                    [(ngModel)]="partEntryInfo.scrap_Date" placeholder="MM/dd/yyyy"
                                                    (dateChange)="OnDateChange(partEntryInfo, 'ScrapDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Mfg Kit #:</td>
                                        <td>
                                            <input type="text" maxlength="50" [(ngModel)]="partEntryInfo.kitNumber"
                                                class="BlackText" size="50" style="margin-top: 10px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Mfg Part #:</td>
                                        <td>
                                            <input type="text" maxlength="50" [(ngModel)]="partEntryInfo.partNumber"
                                                class="BlackText" size="50" style="margin-top: 10px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Stock ID:</td>
                                        <td>
                                            <input type="text" maxlength="50" [(ngModel)]="partEntryInfo.stockId"
                                                class="BlackText" size="50" style="margin-top: 8px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Refurbished:</td>
                                        <td>
                                            <input type="checkbox" class="form-check-input"
                                                [(ngModel)]="partEntryInfo.refurbished"
                                                [checked]="partEntryInfo?.refurbished"
                                                style="height: 15px; width: 15px; margin-top: 7px;">
                                            <!-- (change)="NAEventCheck($event, key)" -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2055 Compatible:</td>
                                        <td>
                                            <input type="checkbox" class="form-check-input"
                                                [(ngModel)]="partEntryInfo._2055compatible"
                                                [checked]="partEntryInfo?._2055compatible"
                                                style="height: 15px; width: 15px; margin-top: 7px;">
                                            <!-- (change)="NAEventCheck($event, key)" -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Max # of Repairs:</td>
                                        <td>
                                            <input type="number" [(ngModel)]="partEntryInfo.maxNumberOfRepairs"
                                                autocomplete="off" style="margin-top: 8px;"
                                                (change)="UpdateIntValues(partEntryInfo, 'maxRepairs')">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Prior # of Repairs:</td>
                                        <td>
                                            <input type="number" [(ngModel)]="partEntryInfo.priorNumberOfRepairs"
                                                autocomplete="off" style="margin-top: 8px;"
                                                (change)="UpdateIntValues(partEntryInfo, 'priorRepairs')">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tr>
                                    <td>Starting Hours:</td>
                                    <td align="right">
                                        <input type="number" [(ngModel)]="partEntryInfo.starting_Hours"
                                            autocomplete="off" style="text-align: right;"
                                            (change)="UpdateIntValues(partEntryInfo, 'startingHours')">
                                    </td>
                                </tr>
                                <tr>
                                    <td>Max Hours:</td>
                                    <td align="right">
                                        <input type="number" [(ngModel)]="partEntryInfo.max_Hours" autocomplete="off"
                                            style="margin-top: 8px; margin-bottom: 7px; text-align: right;"
                                            (change)="UpdateIntValues(partEntryInfo, 'maxHours')">
                                    </td>
                                </tr>
                                <tr>
                                    <td>Current Hours:</td>
                                    <td align="right">
                                        {{ partEntryInfo?.currentLifeTimeHours | number:'2.0' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Remaining Hours:</td>
                                    <td style="border-top:1px solid Black" align="right">
                                        <span style="margin-top: 8px;">
                                            {{ partEntryInfo?.remainingHours | number:'2.0' }}
                                        </span>
                                    </td>
                                </tr>
                            </table>

                            <br>
                            <input type="submit" value="Update" class="BlackText"
                                (click)="UpdatePartEntryDetails(partEntryInfo)">&nbsp;
                            <input type="submit" value="Cancel" class="BlackText" (click)="OnCancelClick()">&nbsp;
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Comments">
                    <ng-template matTabContent>
                        <div style="margin-top: 15px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">Comments:</td>
                                        <td>
                                            <textarea rows="10" cols="145" class="BlackText"
                                                [(ngModel)]="partEntryInfo.comments" style="margin-left: 7px;">
                                            </textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>
                            <input type="submit" value="Update" class="BlackText"
                                (click)="UpdatePartEntryDetails(partEntryInfo)">&nbsp;
                            <input type="submit" value="Cancel" class="BlackText" (click)="OnCancelClick()">&nbsp;
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="History">
                    <ng-template matTabContent>
                        <div style="margin-top: 15px; width: 80%;">
                            <table class="BlackText">
                                <tbody>
                                    <tr>
                                        <td>Legend:&nbsp;&nbsp;</td>
                                        <td width="10" align="center">*</td>
                                        <td>= Planned Outage</td>
                                        <td width="10"><br></td>
                                        <td width="10" class="PartHoursExceeded"><br></td>
                                        <td style="padding-left: 5px;"> = Max Hours Exceed</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;"
                                [dataSource]="historyDataSource">
                                <!-- Company Column -->
                                <ng-container matColumnDef="company">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Company
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.company_Code }}
                                    </td>
                                    <td mat-cell *matCellDef="let row"> 1 </td>
                                </ng-container>

                                <!-- Unit Column -->
                                <ng-container matColumnDef="unit">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Unit
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        {{ row.unit }}
                                    </td>
                                </ng-container>

                                <!-- Type Column -->
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Type
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        {{ row.outageType }}
                                    </td>
                                </ng-container>

                                <!-- Date In Column -->
                                <ng-container matColumnDef="dateIn">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Date In
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <a href="javascript:void(0);" (click)="ShowOutageInfoDialogInDate(row)">
                                            <span *ngIf="row.plannedOutageIn === 1">
                                                * {{ row.inDate | date: 'MM/dd/yyyy' }}
                                            </span>
                                            <span *ngIf="row.plannedOutageIn !== 1">
                                                {{ row.inDate | date: 'MM/dd/yyyy' }}
                                            </span>
                                        </a>
                                    </td>
                                </ng-container>

                                <!-- Date Out Column -->
                                <ng-container matColumnDef="dateOut">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Date
                                        Out </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <a href="javascript:void(0);" (click)="ShowOutageInfoDialogOutDate(row)">
                                            <span *ngIf="row.plannedOutageOut === 1">
                                                * {{ row.outDate | date: 'MM/dd/yyyy' }}
                                            </span>
                                            <span *ngIf="row.plannedOutageOut !== 1">
                                                {{ row.outDate | date: 'MM/dd/yyyy' }}
                                            </span>
                                        </a>
                                    </td>
                                </ng-container>

                                <!-- Interval Column -->
                                <ng-container matColumnDef="interval">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;">
                                        Interval </th>
                                    <td mat-cell *matCellDef="let row" class="text-right">
                                        {{ row.intervalHours | number:'1.1-1' }}
                                    </td>
                                </ng-container>

                                <!-- Lifetime Column -->
                                <ng-container matColumnDef="lifetime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;">
                                        Lifetime </th>
                                    <td mat-cell *matCellDef="let row" class="text-right" [ngClass]="{'PartHoursExceeded': partEntryInfo?.max_Hours > 0 && 
                                    row.lifeTimeHours > partEntryInfo?.max_Hours }">
                                        {{ row.lifeTimeHours | number:'1.1-1' }}
                                    </td>
                                </ng-container>

                                <!-- Fired Starts Column -->
                                <ng-container matColumnDef="firedStarts">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Fired
                                        Starts </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <span *ngIf="row.intervalFiredStarts === -1">N/A</span>
                                        <span *ngIf="row.intervalFiredStarts !== -1">
                                            {{ row.intervalFiredStarts }}
                                        </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayHistoryColumns" style="color: white;"
                                    class="header-color">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayHistoryColumns;"></tr>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Serial Numbers ({{ serialNoCount }})" *ngIf="partEntryInfo.kit">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <div class="errormsg" *ngIf="srNoErrorMessage">
                                <!-- <span class="ErrorMsgArea"> {{ srNoErrorMessage }} </span> -->
                                <p [innerHTML]="srNoErrorMessage" class="ErrorMsgArea"
                                    style="margin-bottom: 20px!important;"></p>
                            </div>

                            <table cellpadding="4" cellspacing="0" class="BlackText Border" style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="10">
                                            <input type="button" id="btnImport" value="Import" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="showUploadControls();">
                                            <input type="button" id="btnMove" value="Move" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="onMoveClick()">
                                            <input type="button" id="btnScrap" value="Scrap" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="onScrapClick()">
                                            <input type="submit" value="Delete" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="onDeleteClick()">
                                            <input type="submit" value="Report" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="onReportClick()">
                                            <input type="button" value="Select All" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="SelectAll();">
                                            <input type="button" value="Clear All" class="BlackText"
                                                style="width:75px; margin-left: 4px;" (click)="ClearAll()">
                                        </td>
                                    </tr>
                                    <tr id="trScrap">
                                        <td colspan="10">
                                            <table class="BlackText" style="margin-top: 10px; margin-left: 10px;"
                                                *ngIf="isScrap">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span style="margin-top: 10px;">Scrap Date:</span>
                                                        </td>
                                                        <td>
                                                            <mat-form-field appearance="outline"
                                                                style="height: 20px; margin-left: 5px;">
                                                                <input matInput [matDatepicker]="scrapPicker"
                                                                    placeholder="MM/dd/yyyy" [(ngModel)]="scrapDate"
                                                                    (dateChange)="OnScrapDateChange(scrapDate)">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="scrapPicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #scrapPicker></mat-datepicker>
                                                            </mat-form-field>
                                                        </td>
                                                        <td colspan="2" align="center">
                                                            <input type="submit" value="Submit" class="BlackText"
                                                                style="margin-left: 5px; margin-top: 6px;"
                                                                (click)="ScrapSerialnumber()">
                                                            <input type="button" class="BlackText" value="Cancel"
                                                                style="margin-left: 5px;"
                                                                (click)="onScrapCancelClick()">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr *ngIf="uploadControlsVisible">
                                        <td colspan="10">
                                            <label
                                                style="margin-left: 4px; font-weight: normal; padding-top: 5px;">Select
                                                file to import:</label>
                                            <input type="file" id="fileUpload" class="BlackText"
                                                style="margin-left: 4px;padding-top: 1px; padding-bottom: 5px;"
                                                (change)="onFileSelected($event)">
                                        </td>
                                    </tr>
                                    <tr id="trMove" *ngIf="isMove">
                                        <td colspan="10">
                                            <table class="BlackText">
                                                <tbody>
                                                    <tr>
                                                        <td>Move To Set:</td>
                                                        <td>
                                                            <mat-form-field appearance="outline"
                                                                style="height: 20px; width: 170px; margin-top: 10px;">
                                                                <mat-select [(value)]=" selectedMove"
                                                                    (selectionChange)="onMoveToSetChange($event)">
                                                                    <mat-option value="SPARES">SPARES</mat-option>
                                                                    <mat-option *ngFor="let row of moveToSetsValues"
                                                                        [value]="row.setNumber"> {{ row.setNumber }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <!-- <input type="text" maxlength="10" class="BlackText"
                                                                size="10" style="margin-left: 5px;"
                                                                [(ngModel)]="moveName"> -->
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Move Date:</td>
                                                        <td>
                                                            <mat-form-field appearance="outline"
                                                                style="height: 20px; margin-top: 6px;">
                                                                <input matInput [matDatepicker]="movePicker"
                                                                    placeholder="MM/dd/yyyy" [(ngModel)]="moveDate"
                                                                    (dateChange)="OnMoveDateChange(moveDate)">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="movePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #movePicker></mat-datepicker>
                                                            </mat-form-field>
                                                        </td>
                                                        <td colspan="2" align="center">
                                                            <input type="submit" value="Submit" class="BlackText"
                                                                style="margin-top: 11px; margin-left: 5px;"
                                                                (click)="MoveToSetSerialnumber()">
                                                            <input type="button" class="BlackText" value="Cancel"
                                                                style="margin-left: 5px;"
                                                                (click)="onMoveToSetCancelClick()">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr *ngIf="uploadControlsVisible">
                                        <td colspan="10">
                                            <input type="button" id="btnSubmit" value="Submit" class="BlackText"
                                                style="width:75px; margin-left:4px; " (click)="submitFile();">
                                            <input type="button" id="btnCancel" value="Cancel" class="BlackText"
                                                style="width:75px; margin-left:4px;" (click)="hideUploadControls();">
                                        </td>
                                    </tr>
                                    <tr *ngIf="uploadControlsVisible">
                                        <td colspan="10">
                                            <input type="checkbox"
                                                style="margin-left: 4px;padding-top: 5px; padding-bottom: 5px;"
                                                id="headerRow" [(ngModel)]="isHeaderRow"> Header Row
                                        </td>
                                    </tr>
                                    <tr *ngIf="uploadControlsVisible">
                                        <td colspan="10">
                                            <small style="margin-left: 4px;">Note: File must be in CSV format and have
                                                the following columns in
                                                this order: Serial Number, Part Number, Starting Hours, Start Date
                                            </small>
                                        </td>
                                    </tr>
                                    <tr colspan="10">
                                        <td><br class="BlackText"></td>
                                    </tr>
                                    <!-- <tr class="Header outage-info" style="text-align: center;
                                    font-weight: bold; font-size: 14px;">
                                        <th style="width: 3%;"> </th>
                                        <th>Serial Number</th>
                                        <th>Part Number</th>
                                        <th>Starting<br>Hrs</th>
                                        <th>Start</th>
                                        <th style="width: 7%;">End</th>
                                        <th style="width: 7%;">Fired<br>Starts</th>
                                        <th style="width: 7%;">Hours</th>
                                        <th style="width: 7%;">Lifetime<br>Hours</th>
                                        <th style="width: 7%;">Status</th>
                                        <th style="width: 18%;"><br></th>
                                    </tr>
                                    <tr class="Row1" *ngIf="!isUpdateSrNo">
                                        <td><input type="checkbox" class="BlackText" style="display: none;"></td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="35"
                                                style="margin-top: 5px;" [(ngModel)]="insertSRNO.serialNumber">
                                        </td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="35"
                                                style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                                                [(ngModel)]="insertSRNO.partNumber">
                                        </td>
                                        <td>
                                            <input type="number" maxlength="7" class="BlackText" size="7"
                                                style="margin-top: 5px;" [(ngModel)]="insertSRNO.starting_Hours"
                                                (change)="UpdateStartingHours(insertSRNO, 'startHours', insertSRNO.starting_Hours)">
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="date" id="srDate" name="srDate" [(ngModel)]="addSrNoStartDate"
                                                (change)="onStartDateChange($event)" style="margin-left: 3px;
                                                margin-top: 4px; width: 88px;">
                                        </td>
                                        <td><br></td>
                                        <td><br></td>
                                        <td><br></td>
                                        <td><br></td>
                                        <td><br></td>
                                        <td>
                                            <input type="submit" value="Add" class="blacktext"
                                                (click)="addSerialNumber(insertSRNO)">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;" (click)="cancelAddSerialNumber()">
                                        </td>
                                    </tr>
                                    <tr class="Row2" *ngIf="isUpdateSrNo">
                                        <td><br></td>
                                        <td style="width: 43%;">
                                            <span class="BlackText"> {{ updateSRNO.serial_Number }} </span>
                                        </td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="35"
                                                style="margin-left: 3px; margin-right: 3px;"
                                                [(ngModel)]="updateSRNO.partNumber">
                                        </td>
                                        <td>
                                            <input type="number" maxlength="7" class="BlackText" size="7"
                                                style="margin-top: 5px;" [(ngModel)]="updateSRNO.starting_Hours"
                                                (change)="UpdateStartingHours(updateSRNO, 'startHoursUpdate', updateSRNO.starting_Hours)">
                                        </td>
                                        <td>
                                            <input type="date" id="srDateUpdate" name="srDateUpdate"
                                                [value]="updateSrNoStartDate" [(ngModel)]="updateSrNoStartDate"
                                                (change)="onUpdateStartDateChange($event)" style="margin-left: 3px;
                                                margin-top: 4px; width: 88px;">
                                        </td>
                                        <td>
                                            <span class="BlackText"></span>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><br></td>
                                        <td>
                                            <input type="submit" value="Update" class="blacktext"
                                                (click)="updateSerialNumber(updateSRNO)">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;"
                                                (click)="cancelUpdateSerialNumber(updateSRNO)">
                                        </td>
                                    </tr>
                                    <tr *ngFor="let set of serialNumbers let i = index">
                                        <td align="left">
                                            <input type="checkbox" class="BlackText" [(ngModel)]="set.isSelected"
                                                (ngModelChange)="onSrNoChange(set.serial_Number, $event)">
                                        </td>
                                        <td align="left">
                                            <a href="javascript:void(0);"
                                                (click)="ShowSerialNumberHistoryDialog(set)">{{set.serial_Number}}</a>
                                        </td>
                                        <td align="left">
                                            {{ set.partNumber }}
                                        </td>
                                        <td align="center">
                                            {{ set.starting_Hours | number:'1.2-2' }}
                                        </td>
                                        <td align="center">
                                            {{ set.start_Date | date: 'MM/dd/yyyy' }}
                                        </td>
                                        <td align="center">
                                            {{ set.end_Date | date: 'MM/dd/yyyy' }}
                                        </td>
                                        <td align="center">
                                            <span *ngIf=" set.firedStarts === -1"> N/A </span>
                                            <span *ngIf=" set.firedStarts !== -1"> {{ set.firedStarts }} </span>
                                        </td>
                                        <td align="center">
                                            {{ set.hours | number:'1.2-2' }}
                                        </td>
                                        <td align="center">
                                            {{ set.lifeTimeHours | number:'1.2-2' }}
                                        </td>
                                        <td align="center">
                                            {{ set.statusDescription }}
                                        </td>
                                        <td align="left">
                                            <input type="submit" value="Edit" class="BlackText" style="margin-top: 3px;"
                                                (click)="EditSerialNumber(set, i)" [disabled]="isUpdateSrNo">
                                            <input type="submit" value="Unscrap" class="BlackText"
                                                style="margin-left: 3px;" *ngIf="set.statusDescription === 'Scrapped'"
                                                (click)="UnscrapSerialnumber(set.serial_Number)">
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>

                            <table mat-table matSort style="margin-top: -10px; border: 1px solid grey;"
                                aria-describedby="" [dataSource]="serialNumbersDataSource">

                                <ng-container matColumnDef="chkbox">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        style="color: white; width: 3%;" class="text-center"></th>
                                    <td mat-cell *matCellDef="let row" class="text-left" align="left">
                                        <input type="checkbox" class="BlackText" [(ngModel)]="row.isSelected"
                                            (ngModelChange)="onSrNoChange(row.serial_Number, $event)"
                                            *ngIf="row.serial_Number != '0'">
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="SrNo">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Serial Number </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <input type="text" maxlength="50" class="BlackText" size="30"
                                            *ngIf="row.serial_Number == '0'" [(ngModel)]="insertSRNO.serialNumber">

                                        <span *ngIf="row.serial_Number != '0'">
                                            <a href="javascript:void(0);"
                                                (click)="ShowSerialNumberHistoryDialog(row)">{{row.serial_Number}}</a>
                                        </span>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="partNo">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Part Number </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <input type="text" maxlength="50" class="BlackText" size="30"
                                            *ngIf="row.serial_Number == '0'" [(ngModel)]="insertSRNO.partNumber">

                                        <ng-container *ngIf="!isEditing(row) && row.serial_Number != '0'">
                                            {{ row.partNumber }}
                                        </ng-container>
                                        <ng-container *ngIf="isEditing(row) && row.serial_Number != '0'">
                                            <input type="text" [(ngModel)]="updatedPartNo" maxlength="50" size="30"
                                                class="BlackText">
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="startHours">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Starting<br>Hrs
                                    </th>
                                    <td mat-cell *matCellDef="let row" style="text-align: right;">
                                        <input type="text" maxlength="7" class="BlackText" size="7"
                                            *ngIf="row.serial_Number == '0'" [(ngModel)]="insertSRNO.starting_Hours"
                                            step="1" (keypress)="preventDecimal($event)">

                                        <ng-container *ngIf="!isEditing(row) && row.serial_Number != '0'">
                                            {{ row.starting_Hours | number:'1.2-2' }}
                                        </ng-container>
                                        <ng-container *ngIf="isEditing(row) && row.serial_Number != '0'">
                                            <input type="text" [(ngModel)]="updatedStartHours" maxlength="7" size="7"
                                                class="BlackText" step="1" (keypress)="preventDecimal($event)">
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="startDate">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Start
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <input type="date" id="srDate" name="srDate" [(ngModel)]="addSrNoStartDate"
                                            (change)="onStartDateChange($event)" *ngIf="row.serial_Number == '0'"
                                            style="width: 88px;">

                                        <ng-container *ngIf="!isEditing(row) && row.serial_Number != '0'">
                                            {{ row.start_Date | date: 'MM/dd/yyyy'}}
                                        </ng-container>
                                        <ng-container *ngIf="isEditing(row) && row.serial_Number != '0'">
                                            <input type="date" id="srDate" name="srDate"
                                                [(ngModel)]="updateSrNoStartDate"
                                                (change)="onUpdateStartDateChange($event)" style="width: 88px;">
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="endDate">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center"> End
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        {{ row.end_Date | date: 'MM/dd/yyyy' }}
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="firedStarts">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Fired<br>Starts
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <span *ngIf=" row.firedStarts === -1 && row.serial_Number != '0'"> N/A </span>
                                        <span *ngIf=" row.firedStarts !== -1 && row.serial_Number != '0'"> {{
                                            row.firedStarts }} </span>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="hours">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Hours
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <span *ngIf="row.serial_Number != '0'">{{ row.hours | number:'1.2-2' }}</span>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="lifetimeHours">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Lifetime<br>Hours
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <span *ngIf="row.serial_Number != '0'">{{ row.lifeTimeHours | number:'1.2-2'
                                            }}</span>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef style="color: white; width: 12%;"
                                        class="text-center"> Status
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <span *ngIf="row.serial_Number != '0'">{{ row.statusDescription }}</span>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef scope="col" style="width: 20%;"></th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="!isEditing(row)">
                                            <input type="submit" value="Add" class="BlackText"
                                                (click)="addSerialNumber(insertSRNO)" style="margin-left: 3px;"
                                                *ngIf="row.serial_Number == '0'">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;" *ngIf="row.serial_Number == '0'"
                                                (click)="cancelAddSerialNumber()">

                                            <input type="submit" value="Edit" class="BlackText"
                                                *ngIf="row.serial_Number != '0'" style="margin-left: 3px;"
                                                (click)="EditSerialNumber(row)">
                                            <input type="submit" value="Unscrap" class="BlackText"
                                                style="margin-left: 3px;"
                                                (click)="UnscrapSerialnumber(row.serial_Number)"
                                                *ngIf="row.serial_Number != '0' && row.statusDescription === 'Scrapped'">
                                        </ng-container>

                                        <ng-container *ngIf="isEditing(row)">
                                            <input type="submit" value="Update" class="BlackText"
                                                *ngIf="row.serial_Number != '0'" (click)="updateSerialNumber(row)">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                *ngIf="row.serial_Number != '0'" style="margin-left: 3px;"
                                                (click)="cancelUpdateSerialNumber(row)">
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="srNosColumns" style="color: white; height: 20px;"
                                    class="header-color">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: srNosColumns;"
                                    style="height: 40px !important;">
                                </tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data</td>
                                </tr>
                            </table>
                            <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons
                                [pageSizeOptions]="[20, 40, 80, 100, 200, 500]" aria-label="Select page of users">
                            </mat-paginator>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Repairs ({{ repairsCount }})">
                    <ng-template matTabContent>
                        <div style="margin-top: 15px; width: 80%;" *ngIf="repairsCount === 0">
                            <table class="BlackText" cellpadding="4" cellspacing="0"
                                style="width: 80%; margin-top: 1px;">
                                <tbody>
                                    <tr class="header-color" style="height: 28px;">
                                        <th>Shipped</th>
                                        <th>Returned</th>
                                        <th>Plant</th>
                                        <th>Unit</th>
                                        <th>Repair Facility</th>
                                        <th>Internal Ref #</th>
                                        <th>External Ref #</th>
                                    </tr>
                                    <tr style="height: 40px;">
                                        <td colspan="6" align="center"><i>No repairs found</i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style="margin-top: 15px; width: 80%;" *ngIf="repairsCount !== 0">
                            <input type="submit" value="New Repair" class="BlackText" (click)="onNewRepairClick()">

                            <table mat-table matSort [dataSource]="repairsDataSource"
                                style="margin-top: 7px; border: 1px solid grey;">
                                <!-- Shipped Number Column -->
                                <ng-container matColumnDef="shipped">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Shipped
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        {{ row.startDate | date: 'MM/dd/yyyy' }}
                                    </td>
                                    <td mat-cell *matCellDef="let row"> 1 </td>
                                </ng-container>

                                <!-- Returned Column -->
                                <ng-container matColumnDef="returned">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;">
                                        Returned </th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        {{ row.endDate | date: 'MM/dd/yyyy' }}
                                    </td>
                                </ng-container>

                                <!-- Plant Column -->
                                <ng-container matColumnDef="plant">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Plant
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.companyCode }}
                                    </td>
                                </ng-container>

                                <!-- Unit Column -->
                                <ng-container matColumnDef="unit">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Unit
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.unit }}
                                    </td>
                                </ng-container>

                                <!-- Repair Facility Column -->
                                <ng-container matColumnDef="repairFacility">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Repair
                                        Facility </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.repairFacility }}
                                    </td>
                                </ng-container>

                                <!-- Internal Ref Column -->
                                <ng-container matColumnDef="internalRef">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;">
                                        Internal Ref # </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.internalReferenceNumber }}
                                    </td>
                                </ng-container>

                                <!-- External Ref Column -->
                                <ng-container matColumnDef="externalRef">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;">
                                        External Ref # </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        {{ row.externalReferenceNumber }}
                                    </td>
                                </ng-container>

                                <!-- View Column -->
                                <ng-container matColumnDef="view">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <a href="javascript:void(0);" (click)="ShowRepairInfoDialog(row)">View</a>
                                    </td>
                                </ng-container>

                                <!-- Delete Column -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row" class="text-center">
                                        <a href="javascript:void(0);" (click)="onDeleteRepairClick(row)">Delete</a>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayRepaiesColumns" style="color: white;"
                                    class="header-color">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayRepaiesColumns;"
                                    [ngStyle]="{'background-color': row.rowColor}"></tr>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Links ({{ links.length}})">
                    <ng-template matTabContent>

                        <div class="ErrorMsgArea" *ngIf="errorMessage">
                            <span class="errormsg"> {{ errorMessage }} </span>
                        </div>

                        <div style="margin-top: 15px;">
                            <table cellpadding="4" cellspacing="0" class="BlackText Border">
                                <tbody>
                                    <tr class="Header">
                                        <th style="background-color: #009dd0; text-align: center;">Link</th>
                                        <th></th>
                                    </tr>
                                    <tr class="Row1">
                                        <td style="border-bottom: 1px solid black">
                                            <div style="margin-top: 7px;">
                                                Description: <input type="text" maxlength="100" class="BlackText"
                                                    size="100" [(ngModel)]="description">
                                            </div>
                                            <div style="margin-top: 7px; margin-bottom: 7px;">
                                                Link: <mat-form-field appearance="outline" style="height: 20px;">
                                                    <mat-select [(value)]="linkSelected"
                                                        (selectionChange)="onLinkChange($event)">
                                                        <mat-option *ngFor="let row of dynamicLinks" [value]="row.name">
                                                            {{ row.dynamicLink }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field> <input type="text" maxlength="1000" class="BlackText"
                                                    size="80" style="margin-left: 10px;" [(ngModel)]="linkDescription">
                                            </div>
                                        </td>
                                        <td style="width: 1%;">
                                        </td>
                                        <td style="border-bottom: 1px solid black">
                                            <input type="submit" value="Insert" class="BlackText"
                                                (click)="InsertLink()">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;" (click)="onAddClickCancel()">
                                            <br>
                                            <input type="button" value="Document Repository" style="margin-top: 15px;"
                                                onclick="window.open('https://collab001-hou.sp.chevron.net/sites/cgpmpe/partstracking/default.aspx','_blank');">
                                        </td>
                                    </tr>
                                    <tr style="text-align: left;" *ngFor="let linkItem of links">
                                        <td style="display:none;">
                                            {{ linkItem.recordNumber }}
                                        </td>
                                        <td>
                                            <div>
                                                <a [href]="linkItem.link" target="_blank">
                                                    {{ linkItem.description }}
                                                </a>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <input type="submit" value="Edit" class="BlackText" style="margin-top: 5px;"
                                                (click)="EditLink(linkItem.recordNumber)">
                                            <input type="submit" value="Delete" class="BlackText"
                                                style="margin-left: 3px;" (click)="DeleteLink(linkItem.recordNumber)">
                                        </td>
                                    </tr>

                                    <!-- Update UI -->
                                    <tr class="Row2" *ngIf="isEdit">
                                        <td>
                                            Descripton: <input maxlength="100" class="BlackText" size="100"
                                                [(ngModel)]="updateLink.description"><br>
                                            <div style="margin-top: 7px; margin-bottom: 7px;">
                                                Link: <mat-form-field appearance="outline" style="height: 20px;">
                                                    <mat-select [(value)]="selectedLinkUpdate"
                                                        (selectionChange)="onLinkUpdateChange($event)">
                                                        <mat-option *ngFor="let row of dynamicLinks" [value]="row.name">
                                                            {{ row.dynamicLink }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <input type="text" maxlength="1000" class="BlackText" size="80"
                                                    style="margin-left: 10px;" [(ngModel)]="updateLink.link">
                                            </div>
                                        </td>
                                        <td style="width: 12% !important;">
                                            <input type="submit" value="Update" class="BlackText"
                                                style="margin-left: 5px;" (click)="UpdateLink()">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 5px;" (click)="onClickCancel()">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>