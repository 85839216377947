import { spGetUnitOutageTypes } from '#models/spGetUnitOutageTypes';
import { UnitTypesService } from '#services/http/unit-types.service';
import { NotificationService } from '#services/notification.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unit-outage-types',
  templateUrl: './unit-outage-types.component.html',
  styleUrl: './unit-outage-types.component.scss'
})

export class UnitOutageTypesComponent {

  unitOutageTypes: spGetUnitOutageTypes[] = [];
  public unitOutageTypesDataSource: MatTableDataSource<spGetUnitOutageTypes>;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  insertUnitOutageType = new spGetUnitOutageTypes();
  code: string = '';
  description: string = '';
  duration: number;
  updatedDesc: string = '';
  updatedDuration: number;
  public unitOutageTypesColumns = ['code', 'description', 'duration', 'actions'];

  constructor(public dialogRef: MatDialogRef<UnitOutageTypeDialogModel>,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, private unitTypeService: UnitTypesService,
    private notifyService: NotificationService,) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getUnitOutageTypesData();
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  public isEditing(row: spGetUnitOutageTypes): boolean {
    return this.editState[row.code] || false;
  }

  getUnitOutageTypesData() {
    this.unitTypeService.getUnitOutageTypesData(this.data.unitType).subscribe((res) => {
      this.unitOutageTypes = res;

      let type = new spGetUnitOutageTypes();
      type.code = '0';
      type.description = '';
      type.duration = null;
      this.unitOutageTypes.unshift(type);

      this.unitOutageTypesDataSource = new MatTableDataSource<spGetUnitOutageTypes>(this.unitOutageTypes);
    });
  }

  preventDecimal(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',' || event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  addUnitOutageType(code: string, description: string) {
    if (code == undefined || code == "") {
      this.notifyService.showError("Code is required.", "");
    }
    else if (description == undefined || description == "") {
      this.notifyService.showError("Description is required.", "");
    }
    else {
      this.insertUnitOutageType.code = code.toUpperCase();
      this.insertUnitOutageType.unitType = this.data.unitType;
      this.insertUnitOutageType.description = description;
      this.insertUnitOutageType.duration = this.duration;

      this.unitTypeService.insertUnitOutageType(this.insertUnitOutageType).subscribe(res => {
        if (res == -1) {

          this.notifyService.showSuccess("Unit Outage Type added successfully.", "");
          this.code = null;
          this.description = null;
          this.duration = null;
          code = '';
          description = '';
          this.duration = null;
          this.getUnitOutageTypesData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelInsertUnitOutageType() {
    this.code = '';
    this.description = '';
    this.duration = null;
  }

  public onEditUnitOutageTypeClick(row: spGetUnitOutageTypes) {
    this.updatedDesc = row.description;
    this.editState[row.code] = true;
    this.updatedDuration = row.duration;
    this.editState[row.duration] = true;
  }

  UpdateUnitOutageTypeDetails(unit: spGetUnitOutageTypes, updatedDesc: string, updatedDuration: number) {
    unit.description = updatedDesc;
    unit.duration = updatedDuration;

    if (unit.description == undefined || unit.description == "") {
      this.notifyService.showError("Description is required.", "");
      this.editState[unit.description] = true;
      this.editState[unit.duration] = true;
    }
    else {
      this.editState[unit.code] = false;
      this.editState[unit.description] = false;
      this.unitTypeService.UpdateUnitOutageTypeDetails(unit).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Unit outage types details modified successfully.", "");
          this.getUnitOutageTypesData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateUnitOutageType(row: spGetUnitOutageTypes) {
    this.editState[row.code] = false;
    this.editState[row.description] = false;
    this.editState[row.duration] = false;
  }

  deleteUnitOutageType(unit: spGetUnitOutageTypes) {
    this.insertUnitOutageType.code = unit.code.toUpperCase();
    this.insertUnitOutageType.unitType = unit.unitType;

    this.unitTypeService.deleteUnitOutageType(this.insertUnitOutageType).subscribe(res => {

      this.notifyService.showSuccess("Outage Type deleted successfully.", "");
      this.insertUnitOutageType = new spGetUnitOutageTypes();
      this.getUnitOutageTypesData();
    });
  }

}

export class UnitOutageTypeDialogModel {

}
