import { spGetDLN } from '#models/spGetDLN';
import { spGetMoveToSets } from '#models/spGetMoveToSets';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { spGetUnitTypeComponents } from '#models/spGetUnitTypeComponents';
import { spInsertSerialNumber } from '#models/spInsertSerialNumber';
import { spUpdateSerialNumber } from '#models/spUpdateSerialNumber';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { PartEntryService } from '#services/http/part-entry.service';
import { PartsService } from '#services/http/parts.service';
import { SerialNumberMaintenanceService } from '#services/http/serial-number-maintenance.service';
import { NotificationService } from '#services/notification.service';
import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';
import { SerialnoHistoryDialogComponent } from 'app/core/modals/serialno-history-dialog/serialno-history-dialog.component';

@Component({
  selector: 'app-serial-number-maintenance',
  templateUrl: './serial-number-maintenance.component.html',
  styleUrl: './serial-number-maintenance.component.scss'
})

export class SerialNumberMaintenanceComponent {
  organizations: spGetOrganizations[] = [];
  unitTypes: spGetOrganizations[] = [];
  components: spGetUnitTypeComponents[] = [];
  dlns: spGetDLN[] = [];
  sets: spGetMoveToSets[] = [];

  selectedOrg: string = '';
  selectedUnit: string = '';
  selectedComponent: string = '';
  selecteddln: string = '2';
  selectedDlnNo: number = 2;
  selectedSet: string = '';

  public organizationCode: string = '';
  public unitType: string = '';
  serialNumbers: spGetSerialNumbers[] = [];
  public serialNumbersDataSource: MatTableDataSource<spGetSerialNumbers>;
  serialNoCount: number = 0;
  srNoErrorMessage: string = '';

  isScrap: boolean = false;
  scrapDate: Date;
  isMove: boolean = false;
  moveDate: Date;
  srNumber = new spGetSerialNumbers();
  scrapsrNos: spGetSerialNumbers[] = [];
  deleteSrNos: spGetSerialNumbers[] = [];
  insertSRNO = new spInsertSerialNumber();
  updateSRNO = new spUpdateSerialNumber();
  currentDate = new Date();
  addSrNoStartDate: Date;
  updateSrNoStartDate: string = '';
  tabIndex = 0;
  isHeaderRow = false;
  uploadControlsVisible: boolean = false;
  selectedFile: File | null = null;
  selectedMove = 'SPARES'
  moveToSetSrNos: spGetSerialNumbers[] = [];
  moveName: string = '';
  editable: boolean = false;
  isUpdateSrNo = false;
  moveToSetsValues: spGetMoveToSets[] = [];

  updatedPartNo: string = '';
  updatedStartHours?: number = 0;
  updatedStartDate: Date;

  public organizationsDataSource: MatTableDataSource<spGetSerialNumbers>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  public srNosColumns = ['chkbox', 'SrNo', 'partNo', 'startHours', 'startDate', 'endDate', 'firedStarts', 'hours', 'lifetimeHours', 'status', 'actions'];

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, public dialog: MatDialog,
    private outSummaryService: OutageSummaryService, private partsService: PartsService,
    private partEntryService: PartEntryService,
    private srnoMaintenanceService: SerialNumberMaintenanceService, public datepipe: DatePipe) {
  }

  ngOnInit(): void {
    this.getOrganizations();
    this.getDLNs();
  }

  public isEditing(row: spGetSerialNumbers): boolean {
    return this.editState[row.serial_Number] || false;
  }

  preventDecimal(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',' || event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;
    });
  }

  onOrganizationChange(event: any) {
    this.organizationCode = event.value;
    this.selectedOrg = this.organizationCode;
    this.unitTypes = null;
    this.components = null;
    this.sets = null;
    this.serialNumbers = null;
    this.serialNumbersDataSource = null;
    this.selectedUnit = null;
    this.getUnitTypes();
  }

  getDLNs() {
    this.srnoMaintenanceService.getDLNs().subscribe((res) => {
      this.dlns = res;
    });
  }

  onDLNChange(event: any) {
    this.selecteddln = event.value;
    this.selectedDlnNo = event.value;
    this.sets = null;
    this.serialNumbers = null;
    this.serialNumbersDataSource = null;
    this.selectedSet = null;
    this.selectedSet = null;
    this.getSets();
  }

  async getUnitTypes() {
    const res = await this.outSummaryService.getUnitTypes(this.organizationCode).toPromise();
    this.unitTypes = res;
  }

  onUnitTypeChange(event: any) {
    this.unitType = event.value;
    this.selectedUnit = this.unitType;
    this.components = null;
    this.sets = null;
    this.serialNumbers = null;
    this.serialNumbersDataSource = null;
    this.selectedComponent = null;
    this.selectedSet = null;
    this.getComponent();
  }

  getComponent() {
    this.partsService.getComponents(this.organizationCode, this.unitType).subscribe((res) => {
      this.components = res;
    });
  }

  onComponentChange(event: any) {
    this.selectedComponent = event.value;
    this.sets = null;
    this.serialNumbers = null;
    this.serialNumbersDataSource = null;
    this.selectedSet = null;
    this.getSets();
  }

  getSets() {
    this.srnoMaintenanceService.getAllSets(this.organizationCode, this.selectedComponent,
      this.selectedUnit, this.selecteddln).subscribe((res) => {
        this.sets = res;
      });
  }

  onSetsChange(event: any) {
    this.selectedSet = event.value;
    this.srNoErrorMessage = '';
    // Get Move to Set Values
    this.getMoveToSetValues(this.selectedDlnNo);
    this.getSerialNumbers();
  }

  getMoveToSetValues(dln: number) {
    this.partEntryService.getAllMoveToSetValues(this.organizationCode, this.selectedComponent, this.selectedUnit,
      this.selectedSet, dln).subscribe((res) => {
        this.moveToSetsValues = res;
      });
  }

  getSerialNumbers() {
    // if (this.organizationCode != null && this.selectedComponent != null &&
    //   this.selectedUnit != null && this.selecteddln != null) {
    this.srnoMaintenanceService.getSerialNumbersData(this.organizationCode, this.selectedComponent,
      this.selectedUnit, this.selectedSet, this.selecteddln).subscribe((res) => {
        this.serialNumbers = null;
        this.serialNumbers = res;
        this.serialNoCount = this.serialNumbers.length;
        let type = new spGetSerialNumbers();
        type.serial_Number = '0';
        type.partNumber = '';
        this.serialNumbers.unshift(type);

        this.serialNumbersDataSource = new MatTableDataSource<spGetSerialNumbers>(this.serialNumbers);
        setTimeout(() => this.serialNumbersDataSource.paginator = this.paginator);
          if (this.serialNumbers.length == 0) {
            this.notifyService.showError("No records found.", "");
          }        
      });
    //}
  }

  UpdateStartingHours(part: spInsertSerialNumber, type: string, value: any) {
    if (type == 'startHours') {
      this.insertSRNO.starting_Hours = parseInt(value);
    }
    if (type == 'startHoursUpdate') {
      this.updateSRNO.starting_Hours = parseInt(value);
    }
  }

  showUploadControls(): void {
    this.uploadControlsVisible = true;
    this.isMove = false;
    this.isScrap = false;
  }

  hideUploadControls(): void { this.uploadControlsVisible = false; this.selectedFile = null; }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }
  }

  submitFile(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      //this.partEntryInfo.service_Date,

      this.partEntryService.importPartyEntryCSVFile(
        formData,
        this.organizationCode,
        this.selectedComponent,
        this.selectedUnit,
        this.selectedSet,
        null,
        this.isHeaderRow,
        this.selectedDlnNo
      ).subscribe(
        res => {
          if (res.validationErrors) {
            // window.location.reload();
            this.notifyService.showError(
              res.validationErrors.join('<br/>'),
              'Validation Errors'
            );
            this.getSerialNumbers();
            // window.location.reload();
          } else {
            //window.location.reload();
            this.getSerialNumbers();
            this.notifyService.showSuccess('File uploaded successfully.', 'Success');
            this.uploadControlsVisible = false;
            this.isHeaderRow = false;
          }
        },
        error => {
          if (error.validationErrors) {
            this.notifyService.showError(
              error.validationErrors.join('<br/>'),
              'Validation Errors'
            );
            this.getSerialNumbers();
          } else {
            this.notifyService.showError(error.message, 'Error');
          }
        }
      );
    } else {
      this.notifyService.showError('Please select a file to upload.', 'Error');
    }
  }

  SelectAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = true;
    });
  }

  ClearAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = false;
    });
  }

  onSrNoChange(number: string, event: any) {
    this.serialNumbers.find(x => x.serial_Number == number).isSelected = event;
  }

  onDeleteClick() {
    this.deleteSrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.deleteSrNos.length > 0) {
      this.partEntryService.deleteSrNumbers(this.deleteSrNos).subscribe(res => {
        this.deleteSrNos = null;
        this.getSerialNumbers();
        this.notifyService.showSuccess("Serial number deleted successfully.", "");
      });
    }
    else {
      this.notifyService.showError("No serial numbers selected.", "");
    }
  }

  addSerialNumber(srNo: spInsertSerialNumber) {

    if (this.insertSRNO.serialNumber == '') {
      this.notifyService.showError("Serial number is required.", "");
    }
    else if (this.insertSRNO.start_Date == undefined || this.insertSRNO.start_Date == null) {
      this.notifyService.showError("Start date is required.", "");
    }
    else if (this.insertSRNO.start_Date > this.currentDate) {
      this.notifyService.showError("Start date cannot be greater than today.", "");
    }
    // // else if (this.insertSRNO.starting_Hours == 0) {
    // //   this.notifyService.showError("Starting hours is required.", "");
    // // }
    else if (this.insertSRNO.starting_Hours < 0) {
      this.notifyService.showError("Starting hours cannot be less than zero.", "");
    }
    else {
      this.insertSRNO.organizationCode = this.organizationCode;
      this.insertSRNO.componentCode = this.selectedComponent;
      this.insertSRNO.frameType = this.selectedUnit;
      this.insertSRNO.setNumber = this.selectedSet;
      this.insertSRNO.serialNumber = srNo.serialNumber.replace(/\s/g, "");
      this.insertSRNO.partNumber = srNo.partNumber == '' ? null : srNo.partNumber;
      this.insertSRNO.starting_Hours = srNo.starting_Hours;
      this.insertSRNO.dln = this.selectedDlnNo;

      this.partEntryService.insertSerialNumber(this.insertSRNO).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Serial number added successfully.", "");
          this.insertSRNO.serialNumber = '';
          this.insertSRNO.partNumber = '';
          this.insertSRNO.starting_Hours = null;
          this.insertSRNO.start_Date = null;
          this.addSrNoStartDate = null;
          this.getSerialNumbers();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelAddSerialNumber(srNo: spInsertSerialNumber) {
    this.insertSRNO.serialNumber = '';
    this.insertSRNO.partNumber = '';
    this.insertSRNO.starting_Hours = null;
    this.insertSRNO.start_Date = null;
    this.addSrNoStartDate = null;
  }

  onStartDateChange(event: any) {
    // //alert(event.target.value);
    const date = event.target.value;
    this.addSrNoStartDate = date;

    if (date != null && date != "") {
      const startDate = new Date(date);
      this.insertSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);
    }
    else {
      this.insertSRNO.start_Date = null;
    }
  }

  OnScrapDateChange(date: Date) {
    if (date != null) {
      const serviceDate = new Date(date!);
      date = this.notifyService.ConvertDateBeforeSave(serviceDate);
    }

    // // this.serialNumbers.forEach(element => {
    // //   if (element.isSelected == true) {
    // //     element.end_Date = date;
    // //   }
    // // });
  }

  onScrapClick() {
    this.isScrap = true;
    this.isMove = false;
    this.uploadControlsVisible = false;
    this.srNoErrorMessage = '';
  }

  onScrapCancelClick() {
    this.isScrap = false;
    this.scrapDate = null;
    this.srNoErrorMessage = '';
  }

  ScrapSerialnumber() {
    this.srNoErrorMessage = '';
    this.scrapsrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.scrapsrNos.length == 0) {
      this.notifyService.showError("No serial numbers selected.", "");
    }
    else if (this.scrapDate == null || this.scrapDate == undefined) {
      this.notifyService.showError("Scrap date is required.", "");
    }
    else if (this.scrapsrNos.length > 0) {
      let scrap_date = this.notifyService.ConvertDateBeforeSave(this.scrapDate);

      this.scrapsrNos.forEach(element => {
        if (element.isSelected == true) {
          element.end_Date = scrap_date;
        }
      });

      // // check if scrap date is less than start date.
      this.scrapsrNos.forEach(element => {

        let sdate = new Date(element.start_Date)
        let scrap_date = new Date(element.end_Date)

        if (sdate > scrap_date) {
          const message = '<p></p>' + this.srNoErrorMessage
            + '\n<li>' + 'Serial Number '
            + element.serial_Number + ': Scrap date cannot be less than start date.</li>';
          this.srNoErrorMessage = message;
        }
      });
      // // end

      if (this.srNoErrorMessage == '' || this.srNoErrorMessage == null) {
        this.partEntryService.scrapSerialNumbers(this.scrapsrNos).subscribe(res => {
          this.notifyService.showSuccess("Serial number scrapped successfully.", "");
          this.scrapsrNos = null;
          this.isScrap = false;
          this.scrapDate = null;
          this.srNoErrorMessage = '';
          this.getSerialNumbers();
          // // this.notifyService.showSuccess(this.scrapsrNos.length + "Serial Number(s) scrapped.", "");
        });
      }
    }
  }

  UnscrapSerialnumber(number: string) {
    this.srNumber = this.serialNumbers.find(x => x.serial_Number == number);
    this.partEntryService.unscrapSerialNumbers(this.srNumber).subscribe(res => {
      this.notifyService.showSuccess("Serial number unscrapped successfully.", "");
      this.getSerialNumbers();
    });
  }

  onMoveClick() {
    this.isMove = true;
    this.isScrap = false;
    this.uploadControlsVisible = false;
  }

  onMoveToSetCancelClick() {
    this.isMove = false;
    this.srNoErrorMessage = '';
    this.moveDate = null;
    this.selectedMove = 'SPARES';
    this.moveName = '';
  }

  OnMoveDateChange(date: Date) {
    if (date != null) {
      const moveDate = new Date(date!);
      this.moveDate = this.notifyService.ConvertDateBeforeSave(moveDate);
    }
  }

  MoveToSetSerialnumber() {
    this.srNoErrorMessage = '';
    this.moveToSetSrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.moveToSetSrNos.length == 0) {
      this.notifyService.showError("No serial numbers selected.", "");
    }
    else if (this.moveDate == null || this.moveDate == undefined) {
      this.notifyService.showError("Move date is required.", "");
    }
    else if (this.moveDate > this.currentDate) {
      this.notifyService.showError("Move date cannot be greater than today.", "");
    }
    // else if (this.selectedMove == 'NEWSET' && (this.moveName == '' || this.moveName == undefined)) {
    //   this.notifyService.showError("Set number is required when 'New Set' is selected.", "");
    // }
    // else if (this.moveName.toUpperCase() == 'NEWSET' || this.moveName.toUpperCase() == 'SPARES') {
    //   this.notifyService.showError("Set number cannot be NEWSET or SPARES. These are reserved sets and cannot be used.", "");
    // }

    else if (this.moveToSetSrNos.length > 0) {
      let move_date = this.notifyService.ConvertDateBeforeSave(this.moveDate);

      this.moveToSetSrNos.forEach(element => {
        if (element.isSelected == true) {
          element.end_Date = move_date;
          element.movedTo = this.selectedMove;
          element.status = 'M';
        }
      });

      // // check if move date is less than start date.
      this.moveToSetSrNos.forEach(element => {

        let sdate = new Date(element.start_Date)
        let move_date = new Date(element.end_Date)

        if (sdate > move_date) {
          const message = '<p></p>' + this.srNoErrorMessage
            + '\n<li>' + 'Serial Number '
            + element.serial_Number + ': Move date cannot be less than start date.</li>';
          this.srNoErrorMessage = message;
        }
      });
      // // end

      if (this.srNoErrorMessage == '' || this.srNoErrorMessage == null) {
        this.partEntryService.moveToSetSerialNumbers(this.moveToSetSrNos,
          this.selectedMove == 'NEWSET' ? 1 : 0).subscribe(res => {
            this.notifyService.showSuccess("Serial number moved to set successfully.", "");
            this.moveToSetSrNos = null;
            this.onMoveToSetCancelClick();
            this.getSerialNumbers();
          });
      }
    }
  }

  startEdit(row) {
    row.editable = true;
  }

  EditSerialNumber(srNo: spGetSerialNumbers) {
    this.editState[srNo.serial_Number] = true;
    this.editState[srNo.partNumber] = true;
    this.editState[srNo.starting_Hours] = true;
    //this.editState[srNo.start_Date] = true;

    this.updateSrNoStartDate = this.datepipe.transform(srNo.start_Date, 'yyyy-MM-dd'); //srNo.start_Date;
    //this.updateSRNO = srNo;
    //this.serialNumbers.splice(index, 1);

    const startDate = new Date(srNo.start_Date);
    this.updateSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);
    this.updatedPartNo = srNo.partNumber;
    this.updatedStartHours = srNo.starting_Hours;
    //.isUpdateSrNo = true;
  }

  onUpdateStartDateChange(event: any) {
    const date = event.target.value;
    //.addSrNoStartDate = date;

    if (date != null && date != "") {
      const startDate = new Date(date);
      this.updateSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);
      //this.updatedStartDate = this.notifyService.ConvertDateBeforeSave(startDate);
    }
    else {
      this.updateSRNO.start_Date = null;
    }
  }

  updateSerialNumber(srNo: spGetSerialNumbers) {

    if (this.updateSRNO.start_Date == undefined || this.updateSRNO.start_Date == null) {
      this.notifyService.showError("Start date is required.", "");
    }
    else if (this.updateSRNO.start_Date > this.currentDate) {
      this.notifyService.showError("Start date cannot be greater than today.", "");
    }
    // // else if (this.updateSRNO.starting_Hours == 0) {
    // //   this.notifyService.showError("Starting hours is required.", "");
    // // }
    else if (this.updateSRNO.starting_Hours < 0) {
      this.notifyService.showError("Starting hours cannot be less than zero.", "");
    }
    else {
      this.updateSRNO.organizationCode = this.organizationCode;
      this.updateSRNO.componentCode = this.selectedComponent;
      this.updateSRNO.frameType = this.unitType;
      this.updateSRNO.setNumber = this.selectedSet;
      this.updateSRNO.serial_Number = srNo.serial_Number;

      this.updateSRNO.partNumber = this.updatedPartNo == '' ? null : this.updatedPartNo;
      this.updateSRNO.starting_Hours = this.updatedStartHours;
      this.updateSRNO.dln = this.selectedDlnNo;

      this.partEntryService.updateSerialNumber(this.updateSRNO).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Serial number updated successfully.", "");

          this.editState[srNo.serial_Number] = false;
          this.editState[srNo.partNumber] = false;
          this.editState[srNo.starting_Hours] = false;

          this.updateSRNO = new spUpdateSerialNumber(); //null;
          this.getSerialNumbers();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateSerialNumber(srNo: spGetSerialNumbers) {
    this.editState[srNo.serial_Number] = false;
    this.editState[srNo.partNumber] = false;
    this.editState[srNo.starting_Hours] = false;
    this.updateSRNO = new spUpdateSerialNumber();
    //this.isUpdateSrNo = false;
    //.getSerialNumbers();
    ////window.location.reload();
  }

  ShowSerialNumberHistoryDialog(serialNo: spGetSerialNumbers) {
    this.dialog.open(SerialnoHistoryDialogComponent, {
      width: "55%",
      height: "80%",
      data: {
        OrgCode: this.organizationCode, FrameType: this.selectedUnit, CompCode: this.selectedComponent,
        Dln: serialNo.dln, SerialNumber: serialNo.serial_Number, setNumber: this.selectedSet,
        id: '0'
      },
    });
  }

  ShowPartInfoDialog() {
    this.dialog.open(PartInformationDialogComponent, {
      width: "65%",
      height: "95%",
      data: {
        OrgCode: this.organizationCode, CompCode: this.selectedComponent,
        FrType: this.selectedUnit, SetNo: this.selectedSet
      },
    });
  }

  public onReportClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/serial-number-report'], {
        queryParams: {
          OrgCode: this.organizationCode,
          FrameType: this.selectedUnit,
          CompCode: this.selectedComponent,
          SetNo: this.selectedSet,
          Dln: this.selecteddln
        },
      })
    );

    window.open(url, '_blank');
  }
}

