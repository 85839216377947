<div class="row" style="background-color: white; width: 100%;">

    <div style="float:left; margin-top: 15px; margin-left: 20px; width: 99%;">
        <table class="BlackText">
            <tbody>
                <tr>
                    <th align="left">From Date:</th>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 185px;
                        margin-top: 5px; padding-left: 5px;">
                            <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" [ngModel]="fromDate"
                                (dateChange)="OnFromDateChange('From', $event)">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>

                    <th align="left" style="padding-left: 30px;">To Date:</th>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 185px; padding-left: 5px;">
                            <input matInput [matDatepicker]="pickerTo" placeholder="mm/dd/yyyy" [(ngModel)]="toDate"
                                (dateChange)="OnFromDateChange('To', $event)">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                </tr>
                <tr>
                    <th align="left">Table:</th>
                    <td>
                        <mat-form-field appearance="outline"
                            style="height: 20px; width: 185px; margin-top: 10px; padding-left: 5px;">
                            <mat-select [(value)]="selectedTable" (selectionChange)="onTableChange($event)">
                                <mat-option value="">All Tables</mat-option>
                                <mat-option *ngFor="let row of tables" [value]="row.tables">
                                    {{ row.tables }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <th style="padding-left: 30px;">User:</th>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 185px;
                        margin-top: 10px; padding-left: 5px;">
                            <mat-select [(value)]="selectedUser" (selectionChange)="onUserChange($event)">
                                <mat-option value="">All Users</mat-option>
                                <mat-option *ngFor="let row of users" [value]="row.userID">
                                    {{ row.userID }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                </tr>
                <tr>
                    <th style="align-content: baseline; padding-top: 10px;">Status:</th>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 185px;
                        margin-top: 10px; padding-left: 5px;">
                            <mat-select [(value)]="selectedStatus" (selectionChange)="onStatusChange($event)">
                                <mat-option value="">All Statuses</mat-option>
                                <mat-option *ngFor="let row of statuses" [value]="row.status">
                                    {{ row.status }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <th style="padding-left: 30px;">
                        <input type="submit" value="Submit" class="BlackText; to-top" style="margin-top: 15px;"
                            (click)="onSubmitClick()">
                    </th>
                </tr>
            </tbody>
        </table>
        <br>
        <span class="BlackText">Status Legend:&nbsp;&nbsp;I=Insert&nbsp;&nbsp;D=Delete&nbsp;&nbsp;U=Update</span>

        <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby=""
            [dataSource]="auditLogDataSource" *ngIf="auditLogList.length > 0">

            <ng-container matColumnDef="userId">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    User ID
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    {{ row.userID }}
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="log">
                <th mat-header-cell *matHeaderCellDef style="color: white; width: 12%;" class="text-center">
                    Log Date/Time
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.serial_Number != '0'">{{ row.dateTime | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="table">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Table
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.serial_Number != '0'">{{ row.table }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Status
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.status }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key1Name">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 1 Name
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key1Name }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key1Value">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 1 Value
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key1Value }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key2Name">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 2 Name
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key2Name }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key2Value">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 2 Value
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key2Value }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key3Name">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 3 Name
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key3Name }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key3Value">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 3 Value
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key3Value }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key4Name">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 4 Name
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key4Name }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="key4Value">
                <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                    Key 4 Value
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <span *ngIf="row.serial_Number != '0'">{{ row.key4Value }}</span>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="auditLogsColumns" style="color: white; height: 20px;"
                class="header-color">
            </tr>
            <tr mat-row *matRowDef="let row; columns: auditLogsColumns;" style="height: 40px !important;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons *ngIf="auditLogList.length > 0"
            [pageSizeOptions]="[20, 50, 100, 200, 300, 500, 1000]" aria-label="Select page of users">
        </mat-paginator>
    </div>
</div>