import { Component, OnInit, signal, inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CompaniesService } from '#services/http/companies.service';
import { NotificationService } from '#services/notification.service';
import { AuthService } from 'app/core/authentication/auth.service';
import { GetOrganizations } from '#models/GetOrganizations';
import { spGetCompanies } from '#models/spGetCompanies';
import { spGetCompany } from '#models/spGetCompany';
import { spGetCompanyUnits } from '#models/spGetCompanyUnits';
import { Company } from '#models/company';
import { HttpResponse } from '@angular/common/http';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpDeleteUnit } from '#models/spDeleteUnit';
import { UnitEditDialogComponent } from 'app/core/modals/unit-edit-dialog/unit-edit-dialog.component';

@Component({
  selector: 'app-tops-companies',
  templateUrl: './tops-companies.component.html',
  styleUrls: ['./tops-companies.component.scss'],
})

export class TopsCompaniesComponent implements OnInit {
  private _liveAnnouncer = inject(LiveAnnouncer); 

  userId: string = '';
  organizations: GetOrganizations[] = new Array();
  companies: spGetCompanies[] = new Array();
  errorMessage: string = '';
  SelectedOrganization: GetOrganizations = new GetOrganizations();
  selectedOrganizationValue: string = '';
  SelectedCompany: spGetCompany = new spGetCompany();
  NewCompany: spGetCompany = new spGetCompany();
  companyUnits: spGetCompanyUnits[] = new Array();
  submitted = false;
  
  errorList: string[] = new Array();
  
  isUpdate: boolean | null = null;
    
  displayedColumns: string[] = [
    "unit",
    "unit-type",
    "fuel-source",
    "dln",
    "peaker",
    "projected-outage-color",
    "up-time",
    "firing-temp",
    "actions"];


  companyForm = new FormGroup({
    organization: new FormControl(''),
    updateForm: new FormGroup({
      companyCode: new FormControl({ disabled: true }),
      organizationCode: new FormControl({ disabled: true }),

      shortName: new FormControl({ disabled:false}),    
      fullName: new FormControl({ disabled:false}),

      piServer: new FormControl(''),
      piUser: new FormControl(''),
      pIPassword: new FormControl(''),
    }),
    newForm: new FormGroup ({
      new_companyCode: new FormControl(''),

      new_shortName: new FormControl(''),    
      new_fullName: new FormControl(''),

      new_piServer: new FormControl(''),
      new_piUser: new FormControl(''),
      new_pIPassword: new FormControl(''),
    })
  });

  constructor(
    private snackBar: MatSnackBar,
    private notifyService: NotificationService,
    public dialog: MatDialog,
    private companiesService: CompaniesService, 
    private authService: AuthService
  ) { }

  ngOnInit() {
    const userInfo = this.authService.getCurrentUser();
    this.userId = userInfo.username;

    // Get Organizations data
    this.companiesService.getOrganizations(this.userId).subscribe((res) => {
      this.organizations = res;
      this.SelectedOrganization = this.organizations[0];
      this.getCompanies();
    });
  }
  ShowUnitEditDialog(unit: spGetCompanyUnits) {
    this.dialog.open(UnitEditDialogComponent, {
      width: "60%",
      height: "95%",
      data: { companyCode: unit.companyCode, organizationCode: unit.organizationCode, unit: unit.unit, frameType: unit.frameType, isEdit: true },
    });
  }
  ShowUnitNewDialog() {
    this.dialog.open(UnitEditDialogComponent, {
      width: "60%",
      height: "95%",
      data: { companyName: this.SelectedCompany.companyName, 
        organizationName: this.SelectedOrganization.description,
        companyCode: this.SelectedCompany.companyCode, 
        organizationCode: this.SelectedOrganization.code, 
        isEdit: false },
    });
  }
  public getCompanies() {
    this.companies = new Array();
    this.SelectedCompany = new spGetCompany();
    this.companyUnits = new Array();
    this.companyForm.markAsPristine();
    this.companyForm.updateValueAndValidity();
    this.companiesService.getCompanies(this.SelectedOrganization.code).subscribe((res) => {
      this.companies = res;
    });
  }

  public getCompany(companyCode: string, updateComniesList: boolean = false) {
    // Get Organizations data
    this.isUpdate = true;
    this.companyUnits = new Array();
    this.companiesService.getCompany(this.SelectedOrganization.code, companyCode).subscribe((res) => {
      this.SelectedCompany = res[0];
      this.getUnits();
    });
    if(updateComniesList){
      this.companies = new Array();
      this.companiesService.getCompanies(this.SelectedOrganization.code).subscribe((res) => {
        this.companies = res;
      });
    }
  }

  getUnits() {
      this.companiesService.getCompanyUnits(this.SelectedOrganization.code, this.SelectedCompany.companyCode).subscribe((unitRes)=>{
        this.companyUnits = unitRes;
      })
  }

  public hasError(formGroup: string | null, controlName: string, errorName: string): boolean {
    if(formGroup == null)
      return false;
    if(this.isUpdate)
      return this.companyForm.controls[formGroup].controls[controlName].dirty && 
        this.companyForm.controls[formGroup].controls[controlName].hasError(errorName) &&
        this.companyForm.controls[formGroup].controls[controlName].value == '';
    else{
      return this.companyForm.controls[formGroup].controls[controlName].hasError(errorName) &&
        this.companyForm.controls[formGroup].controls[controlName].value == '';
    }
  }

  public hasErrorPI(formGroup: string, controlName: string, parentControlName: string | null = null): boolean {
    let returnvalue: boolean = false;
    if(parentControlName != null)
      returnvalue = this.companyForm.controls[formGroup].controls[controlName].touched && 
        this.companyForm.controls[formGroup].controls[controlName].value != "" && 
        (this.companyForm.controls[formGroup].controls[parentControlName].value == ""
          || this.companyForm.controls[formGroup].controls[parentControlName].value == null);
      else{
        returnvalue = this.companyForm.controls[formGroup].controls[controlName].touched && 
          this.companyForm.controls[formGroup].controls[controlName].value != ""
      }
      return returnvalue;
  }

  onSave() {
    this.submitted = true;
    if(this.isUpdate){
      if (this.validateCompanyValues(this.SelectedCompany)) {
        this.companiesService.updateCompany(this.SelectedCompany).subscribe((res: number) => {
            if(res == -1){
              this.notifyService.showSuccess('Company details updated successfully.', '')
            }else{
            }
          }
        );
      }
    }else{
      if (this.validateCompanyValues(this.NewCompany)) {
        this.companiesService.insertCompany(this.NewCompany).subscribe((res: number) => {
            if(res == -1){
              this.getCompany(this.NewCompany.companyCode, true);
            }else{
              this.notifyService.showError("This company already exists.", "");
            }
          }
        );
      }
    }
  }

  confirmDeleteUnit(orgCode: string, companyCode: string, unit: any) {
      const confirmed = window.confirm('Delete this unit?');
      if (confirmed) {
          this.deleteUnit(orgCode, companyCode, unit);
      }
  }

  deleteUnit(organizationCode: string, companyCode: string, unit: string, userID: string | null = null){
    let unitPost = new SpDeleteUnit();
    unitPost.organizationCode = organizationCode;
    unitPost.companyCode = companyCode;
    unitPost.unit = unit;
    unitPost.userID = this.userId;

    this.companiesService.deleteCompanyUnit(unitPost).subscribe((res: number) => {
      if(res == -1){
        this.getUnits();
      }
    });
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  newCompany(){
    this.isUpdate = false;
    this.NewCompany = new spGetCompany();
    this.NewCompany.organizationCode = this.SelectedOrganization.code;
  }

  //created this funtion to use the ngStyle to override angular class bag coloring
  myStyle(color: string): object {
    return {"background-color": color};
  }

  errorStyle(): object{
    return {"border-color": "red",
      "border-width": "5px",
      "color": "red"
    }
  }

  validateCompanyValues(company: spGetCompany): boolean {
    let result = true;
    if(company.companyCode == '' || company.companyCode == null){
      this.notifyService.showError("Company code is required.", "");
      result = false
    }

    if(company.companyName == '' || company.companyCode == null){
      this.notifyService.showError("Company name is required.", "");
      result = false
    }

    if(company.fullName == '' || company.companyCode == null){
      this.notifyService.showError("Full name is required.", "");
      result = false
    }
    
    if(company.pIServer != '' && company.pIServer != null && (company.pIUser == '' || company.pIUser == null)){
      this.notifyService.showError("PI User is required when there is a PI Server.", "");
      result = false
    }
    
    if(company.pIUser != '' && company.pIUser != null && (company.pIServer == '' || company.pIServer == null)){
      this.notifyService.showError("PI Server is required when there is a PI User.", "");
      result = false
    }
    
    if(company.pIPassword != '' && company.pIPassword != null && (company.pIUser == '' || company.pIUser == null)){
      this.notifyService.showError("PI User is required when there is a PI Password.","");
      result = false
    }

    return result;
  }

  deleteCompany(){
    if(confirm('Delete this company and its corresponding units?')){
      this.companiesService.deleteCompany(this.SelectedCompany.organizationCode, this.SelectedCompany.companyCode, this.userId).subscribe((res: number)=>{
        if(res == -1){
          this.notifyService.showSuccess("Company successfully deleted.",'');
          this.getCompanies();
          this.isUpdate = null;
          this.SelectedCompany = null;
          this.companyUnits = new Array();
        }else{

        }
      });
    }
  }

}
