import { Component, Inject, ViewChild } from '@angular/core';
import { UnitDialogModel } from '../unit-dialog/unit-dialog.component';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { spGetUnitTypeSections } from '#models/spGetUnitTypeSections';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '#services/notification.service';
import { UnitTypesService } from '#services/http/unit-types.service';
import { spGetOutagesOutageSections } from '#models/spGetOutagesOutageSections';
import { spMoveUnitTypeSection } from '#models/spMoveUnitTypeSection';
import { spGetUnitTypeComponents } from '#models/spGetUnitTypeComponents';
import { Components } from '#models/Components';
import { spInsertUnitTypeComponent } from '#models/spInsertUnitTypeComponent';

@Component({
  selector: 'app-unit-sections-components',
  templateUrl: './unit-sections-components.component.html',
  styleUrl: './unit-sections-components.component.scss'
})

export class UnitSectionsComponentsComponent {
  unitSections: spGetUnitTypeSections[] = [];
  public unitTypesDataSource: MatTableDataSource<spGetUnitTypeSections>;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  insertUnitSection = new spGetUnitTypeSections();
  updateUnitSection = new spGetUnitTypeSections();
  moveSection = new spMoveUnitTypeSection();
  code: string = '';
  description: string = '';
  updatedDesc: string = '';
  sections: spGetOutagesOutageSections[] = [];
  selectedSection: number = 0;
  isComponentVisible: boolean = false;

  // Unit section components
  unitComponents: spGetUnitTypeComponents[] = [];
  components: Components[] = [];
  selectedComponent: string = '';
  selectedSectionComponent: number = 0;
  selectedPart: number = null;
  insertUnitComponent = new spInsertUnitTypeComponent();
  updateUnitComponent = new spInsertUnitTypeComponent();
  moveComponent = new spInsertUnitTypeComponent();
  public unitComponentsDataSource: MatTableDataSource<spGetUnitTypeComponents>;
  section: number = 0;
  isSectionDisabled = true;
  selectedSectionDescription: string = ''
  numberOf: number = null;
  isNumberOfVisible = true;
  isEditNumberOfVisible = true;

  updatedSection: number = 0;
  updatedSelectedPart: string = ''
  updatedNumberOf: number;

  public unitSectionComponentsColumns = ['component', 'section', 'classification', 'numberOf', 'order', 'actions'];

  constructor(public dialogRef: MatDialogRef<UnitDialogModel>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    private notifyService: NotificationService, private unitTypeService: UnitTypesService,) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getSections();
    this.getComponents();
    this.getUnitTypeSectionsData();
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  public isEditing(row: spGetUnitTypeComponents): boolean {
    return this.editState[row.componentCode] || false;
  }

  getSections() {
    this.unitTypeService.getSectionsData(this.data.unitType).subscribe((res) => {
      this.sections = res;
    });
  }

  onSectionChange(event: any) {
    this.selectedSection = event.value;
  }

  getUnitTypeSectionsData() {
    this.unitTypeService.getUnitTypeSectionsData(this.data.unitType).subscribe((res) => {
      this.unitSections = res;
    });
  }

  getComponents() {
    this.unitTypeService.getComponents(this.data.unitType).subscribe((res) => {
      this.components = res;
    });
  }

  public onSectionClick(set: spGetUnitTypeSections) {
    this.isComponentVisible = true;
    this.section = set.section;
    this.selectedSectionComponent = set.section;
    this.selectedSectionDescription = set.description + ' Section';
    this.getUnitSectionComponents();
  }

  addSection(section: number) {
    if (section == undefined || section == null || section == 0) {
      this.notifyService.showError("Section is required.", "");
    }
    else {
      this.insertUnitSection.section = section;
      this.insertUnitSection.frameType = this.data.unitType;
      if (this.unitSections.length > 0) {
        this.insertUnitSection.order = this.unitSections[0].sectionMaxOrder + 1;
      }
      else {
        this.insertUnitSection.order = 1;
      }
      this.unitTypeService.insertUnitTypeSection(this.insertUnitSection).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Unit Section added successfully.", "");
          this.selectedSection = null;
          //this.insertUnitSection = null;
          this.insertUnitSection = new spGetUnitTypeSections();
          this.getUnitTypeSectionsData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelAddSection() {
    this.selectedSection = null;
  }

  deleteUnitTypeSection(section: spGetUnitTypeSections) {
    this.updateUnitSection.section = section.section;
    this.updateUnitSection.frameType = this.data.unitType;

    this.unitTypeService.deleteUnitTypeSection(this.updateUnitSection).subscribe(res => {
      this.notifyService.showSuccess("Unit section deleted successfully.", "");
      this.updateUnitSection = new spGetUnitTypeSections();
      this.getUnitTypeSectionsData();
    });
  }

  changeUnitTypeSectionOrder(section: spGetUnitTypeSections, order: number) {
    this.moveSection.frameType = this.data.unitType;
    this.moveSection.section = section.section;
    this.moveSection.movement = order;

    this.unitTypeService.UpdateUnitTypeSectionOrder(this.moveSection).subscribe(res => {
      this.notifyService.showSuccess("Order changed successfully.", "");
      //this.moveSection = null;
      this.getUnitTypeSectionsData();
    });
  }

  // Components methods

  getUnitSectionComponents() {
    this.unitTypeService.getUnitSectionComponentsData(this.data.unitType, null, 0,
      this.section.toString(), 0).subscribe((res) => {
        this.unitComponents = res;

        let type = new spGetUnitTypeComponents();
        type.componentCode = '0';
        type.componentName = '';
        this.unitComponents.unshift(type);

        this.unitComponentsDataSource = new MatTableDataSource<spGetUnitTypeComponents>(this.unitComponents);
      });
  }

  onComponentChange(event: any) {
    this.selectedComponent = event.value;
  }

  onComponentSectionChange(event: any) {
    this.selectedSectionComponent = event.value;
  }

  onEditComponentSectionChange(event: any) {
    this.updatedSection = event.value;
  }

  onPartClassificationChange(event: any) {
    this.selectedPart = event.value;

    if (this.selectedPart == 0) {
      this.isNumberOfVisible = false;
    }
    else {
      this.isNumberOfVisible = true;
    }
  }

  onEditPartClassificationChange(event: any) {
    this.updatedSelectedPart = event.value;

    if (this.updatedSelectedPart == '0') {
      this.isEditNumberOfVisible = false;
    }
    else {
      this.isEditNumberOfVisible = true;
    }
  }

  preventDecimal(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',' || event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  addComponent(component: string, section: string, part: string) {
    if (component == '' || component == null) {
      this.notifyService.showError("Component is required.", "");
    }
    else if (this.selectedPart == undefined || this.selectedPart == null) {
      this.notifyService.showError("Classification must be selected.", "");
    }
    else {
      this.insertUnitComponent.frameType = this.data.unitType;
      this.insertUnitComponent.componentCode = this.selectedComponent;
      this.insertUnitComponent.section = section.toString();
      this.insertUnitComponent.numberOf = this.numberOf;
      this.insertUnitComponent.classification = this.selectedPart;
      if (this.unitComponents.length > 1) {
        this.insertUnitComponent.order = this.unitComponents[1].sectionMaxOrder + 1;
      }
      else {
        this.insertUnitComponent.order = 1;
      }
      this.unitTypeService.insertUnitTypeComponent(this.insertUnitComponent).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Component added successfully.", "");
          this.selectedComponent = null;
          this.selectedPart = null;
          this.numberOf = null;
          this.insertUnitComponent = new spInsertUnitTypeComponent();
          this.getUnitSectionComponents();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  canceladdComponent() {
    this.selectedComponent = null;
    this.selectedPart = null;
    this.numberOf = null;
  }

  deleteUnitComponent(component: spGetUnitTypeComponents) {
    this.unitTypeService.deleteUnitComponent(component).subscribe(res => {
      this.notifyService.showSuccess("Component deleted successfully.", "");
      this.getUnitSectionComponents();
    });
  }

  public onEditComponentClick(row: spInsertUnitTypeComponent) {
    this.updatedSection = Number(row.section);
    this.updatedNumberOf = row.numberOf;
    this.updatedSelectedPart = row.classification.toString();

    this.editState[row.componentCode] = true;
    this.editState[row.section] = true;
    this.editState[row.numberOf] = true;
  }

  cancelUpdateComponentClick(row: spGetUnitTypeComponents) {
    this.editState[row.componentCode] = false;
    this.editState[row.section] = false;
    this.editState[row.number] = false;
  }

  updateComponent(row: spGetUnitTypeComponents, updatedSection: number, updatedSelectedPart: number, updatedNumberOf: number) {
    if (this.updatedSelectedPart == undefined || this.updatedSelectedPart == null) {
      this.notifyService.showError("Classification must be selected.", "");
    }
    else {
      this.updateUnitComponent.frameType = row.frameType;
      this.updateUnitComponent.componentCode = row.componentCode;
      this.updateUnitComponent.section = updatedSection.toString();
      this.updateUnitComponent.numberOf = updatedNumberOf;
      this.updateUnitComponent.classification = Number(updatedSelectedPart);
      this.updateUnitComponent.order = row.order;

      this.editState[row.componentCode] = false;
      this.editState[row.section] = false;
      this.editState[row.number] = false;


      this.unitTypeService.UpdateComponentDetails(this.updateUnitComponent).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Component details updated successfully.", "");
          this.updatedSection = null;
          this.updatedSelectedPart = null;
          this.updatedNumberOf = null;
          this.updateUnitComponent = new spInsertUnitTypeComponent();
          this.getUnitSectionComponents();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  changeComponentOrder(row: spGetUnitTypeComponents, order: number) {
    this.moveComponent.frameType = row.frameType;
    this.moveComponent.componentCode = row.componentCode;
    this.moveComponent.section = row.section.toString();
    this.moveComponent.movement = order;

    this.unitTypeService.UpdateComponentOrder(this.moveComponent).subscribe(res => {
      this.notifyService.showSuccess("Order changed successfully.", "");
      this.getUnitSectionComponents();
    });
  }
}
