import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { SerialNumberMaintenanceService } from '#services/http/serial-number-maintenance.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-serial-number-report',
  templateUrl: './serial-number-report.component.html',
  styleUrl: './serial-number-report.component.scss'
})

export class SerialNumberReportComponent {
  serialNumbers: spGetSerialNumbers[] = [];
  public organizationCode: string = '';
  public frameType: string = '';
  public componentCode: string;
  public setNo: string = '';
  public dln: string = '';
  public date: Date;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private srnoMaintenanceService: SerialNumberMaintenanceService) {
  }

  ngOnInit(): void {
    this.date  = new Date()
    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrgCode'];
      this.frameType = params['FrameType'];
      this.componentCode = params['CompCode'];
      this.setNo = params['SetNo'];
      this.dln = params['Dln'];
    });

    this.getSerialNumbers();
  }

  getSerialNumbers() {
    this.srnoMaintenanceService.getSerialNumbersData(this.organizationCode, this.componentCode,
      this.frameType, this.setNo, this.dln).subscribe((res) => {
        this.serialNumbers = res;
      });
  }

}