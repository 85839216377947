import { TopsHomeService } from '#services/http/tops-home.service';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { vOutageEntry } from '#models/VOutageEntry';
import { Link } from '#models/Link';
import { spGetOutageSets } from '#models/spGetOutageSets';
import { spGetOutagesOutageTypes } from '#models/spGetOutagesOutageTypes';
import { OutageEntryService } from '#services/http/outage-entry.service';
import { OutagesOutageEntryService } from '#services/http/outages-outage-entry.service';
import { NotificationService } from '#services/notification.service';
import { GetFrameDuration } from '#models/GetFrameDuration';
import { MatSnackBar } from '@angular/material/snack-bar';
import { spGetAvaliableSets } from '#models/spGetAvaliableSets';
import { spScrapPart } from '#models/spScrapPart';
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';
import { RepairInformationDialogComponent } from 'app/core/modals/repair-information-dialog/repair-information-dialog.component';
import { spDeleteOutage } from '#models/spDeleteOutage';
import { spInsertLink } from '#models/spInsertLink';
import { PtdynamicLink } from '#models/PtdynamicLink';
import { PartEntryService } from '#services/http/part-entry.service';
import { UnitEditDialogComponent } from 'app/core/modals/unit-edit-dialog/unit-edit-dialog.component';
import { spGetUnit } from '#models/spGetUnit';
import { spGetOutagesSummary } from '#models/spGetOutagesSummary';
import { OutageSummaryService } from '#services/http/outage-summary.service';

@Component({
  selector: 'app-outage-entry',
  templateUrl: './outage-entry.component.html',
  styleUrls: ['./outage-entry.component.scss']
})
export class OutageEntryComponent {
  outageInfo = new vOutageEntry();
  linkStr: string = '';

  // // Set Types
  outageSets: spGetOutageSets[] = [];
  originalSets: spGetOutageSets[] = [];
  compressor: spGetOutageSets[] = [];
  combustion: spGetOutageSets[] = [];
  turbine: spGetOutageSets[] = [];
  generator: spGetOutageSets[] = [];
  gearBox: spGetOutageSets[] = [];
  engine: spGetOutageSets[] = [];
  gasRemovalSystem: spGetOutageSets[] = [];
  coolingSystem: spGetOutageSets[] = [];
  highPressureTurbine: spGetOutageSets[] = [];
  lowPressureTurbine: spGetOutageSets[] = [];
  catalyst: spGetOutageSets[] = [];
  outageTypes: spGetOutagesOutageTypes[] = [];
  availableSets: spGetAvaliableSets[] = [];
  selectedType: string = '';
  startDate?: any;
  endDate?: any;
  orgOutageDate?: any;
  duration?: any;
  previousUnitHours?: number;
  checkEndDate?: Date;
  durations = new GetFrameDuration();
  frameType: string = '';
  public unitType: string = '';
  turnaroundName: string = '';
  componentCode: string = '';
  infoMessages: string[] = [];
  deleteOutage = new spDeleteOutage();
  linkSelected: number = 0;
  dynamicLinks: PtdynamicLink[] = [];
  description: string = '';
  linkDescription: string = '';
  link = new spInsertLink();
  links: Link[] = [];
  selectedLink: string = '';
  selectedLinkUpdate: string = '';
  errorMessage: string = '';
  srNoErrorMessage: string = '';
  isEdit: boolean = false;
  updateLink = new Link();
  unitInfo = new spGetUnit();
  outagesSummaryData: spGetOutagesSummary[] = [];
  constructor(
    private router: Router, private topsHomeService: TopsHomeService,
    private outageOutageEntryService: OutagesOutageEntryService,
    private outageEntryService: OutageEntryService,
    private outSummaryService: OutageSummaryService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private notifyService: NotificationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private partEntryService: PartEntryService
  ) {

  }
  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.outageInfo.organizationCode = params['OrgCode'];
      this.outageInfo.companyCode = params['CompCode'];
      this.outageInfo.frameType = params['FrType'];
      this.outageInfo.unit = params['unit'];
      this.outageInfo.id = params['id'];
      this.outageInfo.outageDate = params['outageDate'];

    })
    this.getLinks();
    //Check for any WARNING messages
    this.CheckComponents()

    this.outageEntryService.getOutageEntry(this.outageInfo.organizationCode, this.outageInfo.companyCode, this.outageInfo.unit, this.outageInfo.outageDate.toString())
      .subscribe((res) => {

        this.outageInfo = res[0];

        this.outageInfo.outageDate = this.outageInfo.outageDate;
        this.orgOutageDate = this.outageInfo.outageDate;
        this.outageInfo.outageEndDate = this.outageInfo.outageEndDate;

        this.outageOutageEntryService.getOutageTypes(this.outageInfo.organizationCode, this.outageInfo.companyCode,
          this.outageInfo.unit).subscribe((res) => {

            this.outageTypes = res;

            if (this.outageTypes.length > 0) {
              this.selectedType = this.outageInfo.outageType;
            }
            else {
              this.selectedType = '';
            }

            this.outageEntryService.getPreviousUnitHours(this.outageInfo.organizationCode, this.outageInfo.companyCode,
              this.outageInfo.unit, this.outageInfo.outageDate.toString()).subscribe((res) => {
                this.previousUnitHours = res[0];
                console.log(this.previousUnitHours)
              });
          });

        // // Get Sets Data
        this.GetSetsData();

      });

    const outageDtStr = this.datePipe.transform(this.outageInfo.outageDate, 'MM/dd/yyy');

    // Get Links data
    this.topsHomeService.getLinksData(this.outageInfo.organizationCode, this.outageInfo.companyCode,
      this.outageInfo.frameType, outageDtStr, 'O').subscribe((res: Link[]) => {
        console.log(res)
        this.links = res;
      });
  }

  getLinks() {
    this.partEntryService.getAllDynamicLinks().subscribe((res) => {
      this.dynamicLinks = res;
    });
  }

  InsertLink() {

    this.link.field1 = this.outageInfo.organizationCode;
    this.link.field2 = this.outageInfo.companyCode;
    this.link.field3 = this.outageInfo.frameType;
    this.link.field4 = this.datePipe.transform(this.outageInfo.outageDate, 'MM/dd/yyy');
    this.link.field5 = null;
    this.link.type = 'O';
    this.link.description = this.description;
    this.link.link = this.linkDescription;
    this.link.subLink = this.selectedLink;

    this.errorMessage = "";
    this.ValidateFields(this.link);

    if (this.errorMessage === "") {
      this.partEntryService.insertLinksData(this.link).subscribe(res => {
        window.location.reload();
      });

      this.link = null;
    }
  }

  onLinkChange(event: any) {
    this.selectedLink = event.value;
  }

  ValidateFields(link: spInsertLink) {
    if (link.description == undefined || link.description == "") {
      this.errorMessage = 'Link description is required.';
    }
    else if (link.link == undefined || link.link == "") {
      this.errorMessage = 'Link required.';
    }
  }
  DeleteLink(recordNumber: number) {
    this.partEntryService.deleteLink(recordNumber).subscribe(res => {
      window.location.reload();
    });
  }

  EditLink(recordNumber: number) {
    this.isEdit = true;
    this.updateLink = this.links.find(x => x.recordNumber == recordNumber);
    this.selectedLinkUpdate = this.updateLink.subLink;
  }

  UpdateLink() {
    this.partEntryService.updateLink(this.updateLink).subscribe(res => {
      window.location.reload();
    });
  }
  onClickCancel() {
    this.updateLink = null;
    this.isEdit = false;
  }

  onAddClickCancel() {
    this.description = '';
    this.linkSelected = 0;
    this.linkDescription = '';
  }

  onLinkUpdateChange(event: any) {
    this.selectedLinkUpdate = event.value;
    this.updateLink.subLink = this.selectedLinkUpdate;
  }
  canShowRepairButton(set: spGetOutageSets): boolean {
    if (
      (set.repairDate === null) &&
      (set.setOut && set.setNumber?.length > 0) &&
      (set.setNumber && set.setOut !== set.setNumber) &&
      (set.scrapDate === null || (set.scrapDate > set.outageDateNew && set.scrapDate > set.outageEndDate))
    ) {
      if ((set.remainingRepairs !== null && set.remainingRepairs <= 0)) { return false; }
      else if (this.outageInfo.unitHours > 0) {
        return true;
      }
    }
    return false;
  }
  onRepairBtnClick(set: spGetOutageSets) {
    if (this.canShowRepairButton(set)) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['tops/part-repair'], {
          queryParams: {
            OrgCode: set.organizationCodeNew,
            CompCode: set.componentCode,
            FrameType: set.frameType,
            StartDate: set.outageDateNew,
            SetNo: set.setNumber,
            id: this.outageInfo.id
          },
        })
      );
      window.open(url, '_blank');
    }
  }
  onShowRepairClick(set: spGetOutageSets) {
    if (set.repairDate &&
      set.repairDate.toString().length > 0
    ) {
      this.dialog.open(RepairInformationDialogComponent, {
        width: "70%",
        height: "95%",
        data: {
          OrgCode: set.organizationCodeNew, CompCode: set.componentCode,
          FrType: set.frameType, SetNo: set.setNumber, startDate: set.outageDateNew
        },
      });
    }
  }

  PartStatus() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-status'], {
        queryParams: {
          OrgCode: this.outageInfo.organizationCode,
          CompCode: this.outageInfo.companyCode,
          Unit: this.outageInfo.unit,
          OutDate: this.outageInfo.outageDate
        },
      })
    );
    window.open(url, '_blank');
  }
  GetSetsData() {
    this.topsHomeService.getSetsData(this.outageInfo.organizationCode, this.outageInfo.companyCode,
      this.outageInfo.unit, this.outageInfo.outageDate.toString()).subscribe((res: spGetOutageSets[]) => {
        this.outageSets = res;
        this.originalSets = JSON.parse(JSON.stringify(res));
        this.compressor = this.outageSets.filter(x => x.sectionNew == 1);
        this.fetchAvailableSetsForComponent(this.compressor, 'availableCompressorSets');

        this.combustion = this.outageSets.filter(x => x.sectionNew == 2);
        this.fetchAvailableSetsForComponent(this.combustion, 'availableCombustionSets');

        this.turbine = this.outageSets.filter(x => x.sectionNew == 3);
        this.fetchAvailableSetsForComponent(this.turbine, 'availableTurbineSets');

        this.generator = this.outageSets.filter(x => x.sectionNew == 4);
        this.fetchAvailableSetsForComponent(this.generator, 'availableGeneratorSets');

        this.gearBox = this.outageSets.filter(x => x.sectionNew == 5);
        this.fetchAvailableSetsForComponent(this.gearBox, 'availableGearBoxSets');

        this.engine = this.outageSets.filter(x => x.sectionNew == 6);
        this.fetchAvailableSetsForComponent(this.engine, 'availableEngineSets');

        this.gasRemovalSystem = this.outageSets.filter(x => x.sectionNew == 7);
        this.fetchAvailableSetsForComponent(this.gasRemovalSystem, 'availableGasRemovalSystemSets');

        this.coolingSystem = this.outageSets.filter(x => x.sectionNew == 8);
        this.fetchAvailableSetsForComponent(this.coolingSystem, 'availableCoolingSystemSets');

        this.highPressureTurbine = this.outageSets.filter(x => x.sectionNew == 9);
        this.fetchAvailableSetsForComponent(this.highPressureTurbine, 'availableHighPressureTurbineSets');

        this.lowPressureTurbine = this.outageSets.filter(x => x.sectionNew == 10);
        this.fetchAvailableSetsForComponent(this.lowPressureTurbine, 'availableLowPressureTurbineSets');

        this.catalyst = this.outageSets.filter(x => x.sectionNew == 11);
        this.fetchAvailableSetsForComponent(this.catalyst, 'availableCatalystSets');
      });
  }

  isModified(): boolean {
    return this.outageSets.some((set, index) => {
      return set.setNumber != this.originalSets[index].setNumber;
    })
  }

  fetchAvailableSetsForComponent(section: spGetOutageSets[], availableSetsKey: string) {
    section.forEach((component) => {
      const componentCode = component.componentCode;
      this.outageEntryService.getAvailableSets(this.outageInfo.organizationCode, this.outageInfo.companyCode,
        this.outageInfo.unit, this.outageInfo.outageDate.toString(), this.outageInfo.frameType, this.outageInfo.dln, componentCode).subscribe((res) => {
          component[availableSetsKey] = res || null;
        });
    })
  }
  preventDecimal(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',' || event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  CheckComponents() {
    this.outageEntryService.checkComponents(this.outageInfo.organizationCode, this.outageInfo.companyCode,
      this.outageInfo.unit, this.outageInfo.outageDate.toString()).subscribe((res) => {
        const rawMessages = res[0].message ? res[0].message.split("WARNING:").filter(msg => msg.trim() !== '') : [];
        this.infoMessages = rawMessages.map(msg => `WARNING: ${msg.trim()}`)
      });
  }
  public onSetInOutClick(set: spGetOutageSets) {
    if (this.outageInfo != null) {
      this.dialog.open(PartInformationDialogComponent, {
        width: "70%",
        height: "95%",
        data: {
          OrgCode: set.organizationCodeNew,
          CompCode: set.componentCode,
          FrType: set.frameType,
          SetNo: set.setNumber,
          id: this.outageInfo.id
        },
      });
    }
  }
  onScrapSet(scrapSet: spGetOutageSets) {
    const scrapPart = new spScrapPart();
    scrapPart.Organization_Code = this.outageInfo.organizationCode;
    scrapPart.Component_Code = scrapSet.componentCode;
    scrapPart.Frame_Type = this.outageInfo.frameType;
    scrapPart.Set_Number = scrapSet.setNumber;
    scrapPart.ScrapDate = this.outageInfo.outageDate;

    this.outageEntryService.ScrapPart(scrapPart).subscribe(res => {
      if (res == -1) {
        this.snackBar.open('Part scrapped successfully!', 'Close', {
          duration: 3000
        });
        this.GetSetsData();
      }
      else {
        this.snackBar.open(res.toString());
      }
    });
  }
  OnStartDateChange(event: any) {
    if (event.target.value != null) {
      const sDate = new Date(event.target.value);
      this.outageInfo.outageDate = this.notifyService.ConvertDateBeforeSave(sDate);

      if (this.outageInfo.duration != 0) {
        const calculatedDate = event.target.value.setDate(event.target.value.getDate() + this.outageInfo.duration);
        this.outageInfo.outageEndDate = new Date(calculatedDate);
        this.outageInfo.outageEndDate = this.notifyService.ConvertDateBeforeSave(this.outageInfo.outageEndDate);
      }
    }
    if (event.target.value == null) {
      this.outageInfo.outageDate = null;
    }
  }

  OnEndDateChange(event: any) {
    if (event.target.value != null) {
      const eDate = new Date(event.target.value);
      this.outageInfo.outageEndDate = this.notifyService.ConvertDateBeforeSave(eDate);
      this.outageInfo.outageDate = new Date(this.outageInfo.outageDate);

      if (this.outageInfo.outageDate !== undefined && this.outageInfo.outageDate !== null) {
        var diffTime: any = Math.abs(new Date(this.outageInfo.outageEndDate).getTime() - new Date(this.outageInfo.outageDate).getTime());
        var diffDays: any = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.outageInfo.duration = diffDays;
      }
    }
    if (event.target.value == null) {
      this.outageInfo.outageEndDate = null;
    }
  }

  onDurationChange(dur: number, date: any) {
    this.duration = Number(dur);

    if (date != null) {
      const calculatedDate = new Date(date);
      this.outageInfo.outageEndDate = new Date(calculatedDate.setDate(calculatedDate.getDate() + this.duration));
      this.outageInfo.outageEndDate = this.notifyService.ConvertDateBeforeSave(this.outageInfo.outageEndDate);
    }
  }
  onOutageTypeChange(event: any) {
    this.selectedType = event.value;
    this.outageOutageEntryService.getFrameType(this.outageInfo.organizationCode, this.outageInfo.companyCode,
      this.outageInfo.unit, this.selectedType).subscribe((res) => {
        this.durations = res;

        if (this.durations != null || this.durations != undefined) {
          this.frameType = this.durations.frameType;
          this.outageInfo.duration = this.durations.duration;
        }

        // set turnaround name and end date if start date is not null
        if (this.outageInfo.outageDate != undefined) {
          const year = new Date(this.outageInfo.outageDate).getFullYear().toString().substr(-2);
          this.turnaroundName = '';
          this.turnaroundName = this.outageInfo.organizationCode + '-' + this.outageInfo.companyCode + '-' +
            this.outageInfo.unit + '-' + this.selectedType + "" + year;

          let date = new Date(this.outageInfo.outageDate);
          const calculatedDate = new Date(date);
          this.outageInfo.outageEndDate = new Date(calculatedDate.setDate(calculatedDate.getDate() + this.outageInfo.duration));
          this.outageInfo.outageEndDate = this.notifyService.ConvertDateBeforeSave(this.outageInfo.outageEndDate);
          this.outageInfo.turnaroundName = this.turnaroundName;
        }
      });

    // set turnaround name if start date is null
    if (this.outageInfo.outageDate == null) {
      this.turnaroundName = '';
      this.turnaroundName = this.outageInfo.organizationCode + '-' + this.outageInfo.companyCode + '-' +
        this.outageInfo.unit + '-' + this.selectedType;
    }
  }

  generateTurnaroundName(): string {
    let year_substr = '';
    const date_substr = this.outageInfo.outageDate;

    if (this.outageInfo.outageDate) {
      const outageDate = new Date(this.outageInfo.outageDate);
      year_substr = outageDate.getFullYear().toString().slice(-2);
    }

    const facility_substr = this.outageInfo.organizationCode || '';
    const company_substr = this.outageInfo.companyCode || '';
    const unit_substr = this.outageInfo.unit || '';
    const inspection_substr = this.outageInfo.outageType || '';

    const turnaroundName = `${facility_substr}-${company_substr}-${unit_substr}-${inspection_substr}-${year_substr}`;

    return turnaroundName;
  }
  ShowUnitInfoDialog() {
    this.dialog.open(UnitEditDialogComponent, {
      width: "60%",
      height: "95%",
      data: { companyCode: this.outageInfo.companyCode, organizationCode: this.outageInfo.organizationCode, unit: this.outageInfo.unit, frameType: this.outageInfo.frameType, isEdit: true },
    });
  }
  DeleteOutage() {
    this.deleteOutage.OrganizationCode = this.outageInfo.organizationCode;
    this.deleteOutage.CompanyCode = this.outageInfo.companyCode;
    this.deleteOutage.Unit = this.outageInfo.unit;
    this.deleteOutage.OutageDate = this.outageInfo.outageDate;

    this.outageEntryService.deleteOutage(this.deleteOutage).subscribe((res) => {
      if (res == -1) {
        this.snackBar.open('Outage entry deleted successfully', 'Close', {
          duration: 3000
        });

        const url = this.router.serializeUrl(
          this.router.createUrlTree(['tops/tops-home'])
        );

        this.router.navigateByUrl(url);
      }
      else {
        this.notifyService.showError(res.toString(), "");
      }
    });
  }

  PullPIData() {
    this.topsHomeService.getUnitInfoData(this.outageInfo.organizationCode, this.outageInfo.companyCode, this.outageInfo.unit).subscribe((res) => {
      this.unitInfo = res[0];
      console.log(this.unitInfo)
      this.unitInfo = res[0];
      const piTags = [
        this.unitInfo.piemergencyStopsPoint,
        this.unitInfo.pifiredHoursPoint,
        this.unitInfo.pifiredStartsPoint,
        this.unitInfo.pimanualStartsPoint,
        this.unitInfo.pipeakFiredHoursPoint,
        this.unitInfo.pitotalStartsPoint
      ].filter(tag => tag); // Filter out any undefined or null tags

      if (piTags.length === 0) {
        this.notifyService.showWarning("No PI tags are configured for the Unit.", "");
        return;
      }

      this.outageEntryService.getPiData(this.unitInfo.organizationCode, piTags).subscribe(
        (piData) => {
          this.bindPiData(piData);
        },
        (error) => {
          this.notifyService.showError("Error getting PI data: " + error.message, "");
        }
      );
    }, (error) => {
      this.notifyService.showError("Error getting unit info data: " + error.message, "");
    });
  }

  bindPiData(piData: any) {
    let dataFound = {
      totalStarts: false,
      firedStarts: false,
      emergencyStops: false,
      manualStarts: false,
      peakFiredHours: false,
      firedHours: false
    };

    piData.forEach(data => {
      if (data.name === this.unitInfo.pitotalStartsPoint) {
        this.outageInfo.totalStarts = Number(data.realValue);
        dataFound.totalStarts = true;
      }
      if (data.name === this.unitInfo.pifiredStartsPoint) {
        this.outageInfo.firedStarts = Number(data.realValue);
        dataFound.firedStarts = true;
      }
      if (data.name === this.unitInfo.piemergencyStopsPoint) {
        this.outageInfo.emergencyStops = Number(data.realValue);
        dataFound.emergencyStops = true;
      }
      if (data.name === this.unitInfo.pimanualStartsPoint) {
        this.outageInfo.manualStarts = Number(data.realValue);
        dataFound.manualStarts = true;
      }
      if (data.name === this.unitInfo.pipeakFiredHoursPoint) {
        this.outageInfo.peakFiredHours = Number(data.realValue);
        dataFound.peakFiredHours = true;
      }
      if (data.name === this.unitInfo.pifiredHoursPoint) {
        this.outageInfo.unitHours = Number(data.realValue);
        dataFound.firedHours = true;
      }
    });

    // Show notifications for fields with no data
    if (!dataFound.totalStarts) {
      this.notifyService.showWarning("No PI data available for Total Starts.", "");
    }
    if (!dataFound.firedStarts) {
      this.notifyService.showWarning("No PI data available for Fired Starts.", "");
    }
    if (!dataFound.emergencyStops) {
      this.notifyService.showWarning("No PI data available for Emergency Stops.", "");
    }
    if (!dataFound.manualStarts) {
      this.notifyService.showWarning("No PI data available for Manual Starts.", "");
    }
    if (!dataFound.peakFiredHours) {
      this.notifyService.showWarning("No PI data available for Peak Fired Hours.", "");
    }
    if (!dataFound.firedHours) {
      this.notifyService.showWarning("No PI data available for Fired Hours.", "");
    }
  }


  UpdateOutage() {
    if (this.outageInfo.outageDate == undefined || this.outageInfo.outageDate == null) {
      this.notifyService.showError("Outage date is required.", "");
    }
    else if ((this.outageInfo.outageEndDate == undefined || this.outageInfo.outageEndDate == null) &&
      (this.outageInfo.duration == undefined || this.outageInfo.duration == null || this.outageInfo.duration <= 0)) {
      this.notifyService.showError("Either outage end date or outage duration must be entered.", "");
    }
    else if (this.outageInfo.outageEndDate == undefined || this.outageInfo.outageEndDate == null) {
      this.notifyService.showError("Outage end date is required.", "");
    }
    else if (this.outageInfo.outageEndDate < this.outageInfo.outageDate) {
      this.notifyService.showError("Outage end date cannot be before the outage start date.", "");
    }
    else if (this.outageInfo.duration == undefined || this.outageInfo.duration == null || this.outageInfo.duration <= 0) {
      this.notifyService.showError("Duration must be greater than zero.", "");
    }
    else if (this.selectedType == undefined || this.selectedType == '') {
      this.notifyService.showError("Outage type is required.", "");
    }
    else if (this.outageInfo.unitHours !== undefined && this.outageInfo.unitHours !== null && this.outageInfo.unitHours.toString().length > 0 && this.outageInfo.unitHours < 0) {
      this.notifyService.showError("Unit hours cannot be less than zero.", "");
    }
    else if (this.previousUnitHours !== undefined && this.outageInfo.unitHours !== undefined && this.outageInfo.unitHours !== null && this.outageInfo.unitHours.toString().length > 0 && this.outageInfo.unitHours < this.previousUnitHours) {
      this.notifyService.showError(`Unit hours cannot be less than previous unit hours: ${this.previousUnitHours}.`, "");
    }
    else if (this.outageInfo.peakFiredHours !== undefined && this.outageInfo.peakFiredHours !== null && this.outageInfo.peakFiredHours.toString().length > 0 && this.outageInfo.peakFiredHours < 0) {
      this.notifyService.showError("Peak fired hours cannot be less than zero.", "");
    }
    else if (this.outageInfo.firedStarts !== undefined && this.outageInfo.firedStarts !== null && this.outageInfo.firedStarts.toString().length > 0 && this.outageInfo.firedStarts < 0) {
      this.notifyService.showError("Fired starts cannot be less than zero.", "");
    }
    else if (this.outageInfo.firedStarts !== undefined && this.outageInfo.firedStarts !== null && this.outageInfo.firedStarts.toString().includes('.')) {
      this.notifyService.showError("Fired starts must be an integer value.", "");
    }
    else if (this.outageInfo.manualStarts !== undefined && this.outageInfo.manualStarts !== null && this.outageInfo.manualStarts.toString().length > 0 && this.outageInfo.manualStarts < 0) {
      this.notifyService.showError("Manual starts cannot be less than zero.", "");
    }
    else if (this.outageInfo.manualStarts !== undefined && this.outageInfo.manualStarts !== null && this.outageInfo.manualStarts.toString().includes('.')) {
      this.notifyService.showError("Manual starts must be an integer value.", "");
    }
    else if (this.outageInfo.totalStarts !== undefined && this.outageInfo.totalStarts !== null && this.outageInfo.totalStarts.toString().length > 0 && this.outageInfo.totalStarts < 0) {
      this.notifyService.showError("Total starts cannot be less than zero.", "");
    }
    else if (this.outageInfo.totalStarts !== undefined && this.outageInfo.totalStarts !== null && this.outageInfo.totalStarts.toString().includes('.')) {
      this.notifyService.showError("Total starts must be an integer value.", "");
    }
    else if (this.outageInfo.emergencyStops !== undefined && this.outageInfo.emergencyStops !== null && this.outageInfo.emergencyStops.toString().length > 0 && this.outageInfo.emergencyStops < 0) {
      this.notifyService.showError("Emergency stops cannot be less than zero.", "");
    }
    else if (this.outageInfo.emergencyStops !== undefined && this.outageInfo.emergencyStops !== null && this.outageInfo.emergencyStops.toString().includes('.')) {
      this.notifyService.showError("Emergency stops must be an integer value.", "");
    }
    else if (this.outageInfo.comments && this.outageInfo.comments.length > 4000) {
      this.notifyService.showError("Comments have exceeded the maximum 4000 characters.", "");
    }
    else if (this.outageInfo.outageEndDate.toString().length > 0) {
      this.outageEntryService.getCheckOutageEndDate(this.outageInfo.organizationCode, this.outageInfo.companyCode,
        this.outageInfo.unit, this.outageInfo.outageDate.toString(), this.outageInfo.outageEndDate.toString()).subscribe((res) => {
          this.checkEndDate = res[0];
          console.log(this.checkEndDate);

          if (this.checkEndDate !== undefined && this.checkEndDate !== null) {
            this.notifyService.showError("This outage overlaps another outage for this unit on " + this.checkEndDate.toString(), "");
          } else {
            this.updateOutageEntry();
          }
        });
    } else {
      this.updateOutageEntry();
    }
  }
  updateOutageEntry() {
    this.outageInfo.outageDate = this.outageInfo.outageDate;
    this.outageInfo.outageEndDate = this.outageInfo.outageEndDate;
    this.outageInfo.outageType = this.selectedType;
    this.outageInfo.OrgOutageDate = this.orgOutageDate;

    this.outageEntryService.updateOutageEntry(this.outageInfo).subscribe(res => {
      if (res == -1) {
        this.snackBar.open('Outage entry updated successfully', 'Close', {
          duration: 3000
        });
      } else {
        this.notifyService.showError(res.toString(), "");
      }
    });

    this.UpdateSets();
  }
  UpdateSets() {
    if (this.isModified()) {
      const updatedSets = this.outageSets.filter((set, index) => set.setNumber !== this.originalSets[index].setNumber);

      //send only modified sets to the backend
      this.outageEntryService.updateOutageSets(updatedSets).subscribe((response: any) => {
        console.log(response);
        if (response && response.ok) {
          console.log('Sets updated successfully:', response);
        }
        else {
          this.notifyService.showError("Error updating sets. Please try again.", '')
        }
      }, (error) => {
        this.notifyService.showError(error.toString(), "Error updating sets");
      });
    }
    else {
      console.log('No changes detected')
    }
  }
}
