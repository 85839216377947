import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { spGetOutageSets } from '#models/spGetOutageSets';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OutageEntryService {

  GetOutageEntryUrl = '/OutageEntry/getOutageEntry';
  UpdateOutageUrl = '/OutageEntry/updateOutageEntry';
  GetPreviousUnitHoursUrl = '/OutageEntry/getPreviousUnitHours';
  GetCheckOutageEndDateUrl = '/OutageEntry/getCheckOutageEndDate';
  GetAvailableSetsUrl = '/OutageEntry/getAvailableSets';
  CheckComponentsUrl = '/OutageEntry/checkComponents';
  ScrapPartUrl = '/OutageEntry/scrapPart';
  deleteOutageUrl = '/OutageEntry/deleteOutage';
  updateOutageSetsUrl = '/OutageEntry/updateOutageSets';
  getPIDataUrl = '/OutageEntry/getPIData';
  constructor(private http: HttpClient,) { }

  getPiData(businessUnit: string, piTags: string[]): Observable<any> {
    let params = new HttpParams().set('businessUnit', businessUnit);
    piTags.forEach(tag => {
      params = params.append('piTags', tag);
    });

    return this.http.get<any>(environment.apiEndpoint + this.getPIDataUrl, { params });
  }
 
  deleteOutage(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteOutageUrl, formData);
  }
  updateOutageEntry(formData: any) {
    return this.http.post(environment.apiEndpoint + this.UpdateOutageUrl, formData);
  }
  updateOutageSets(updatedSets: spGetOutageSets[]): Observable<any> {
    return this.http.put(environment.apiEndpoint + this.updateOutageSetsUrl, JSON.stringify(updatedSets), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  ScrapPart(formData: any) {
    return this.http.post(environment.apiEndpoint + this.ScrapPartUrl, formData);
  }

  getPreviousUnitHours(organizationCode: string, companyCode: string, unit: string, outageDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetPreviousUnitHoursUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
        }
      });
  }

  getCheckOutageEndDate(organizationCode: string, companyCode: string, unit: string, outageDate: string, outageEndDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCheckOutageEndDateUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
          outageenddate: outageEndDate,
        }
      });
  }

  getOutageEntry(organizationCode: string, companyCode: string, unit: string, outageDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageEntryUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
        }
      });
  }

  checkComponents(organizationCode: string, companyCode: string, unit: string, outageDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.CheckComponentsUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
        }
      });
  }

  getAvailableSets(organizationCode: string, companyCode: string, unit: string, outageDate: string, frameType: string, dln: boolean, componentCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetAvailableSetsUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
          frameType: frameType,
          dln: dln,
          componentCode: componentCode
        }
      });
  }

}
