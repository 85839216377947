<div class="container" style="background-color: white;">
  <div *ngIf="infoMessages?.length > 0" class="info-section">
    <mat-icon>info</mat-icon>
    <span class="warning-text">
      <ng-container *ngFor="let infoMessage of infoMessages">
        {{infoMessage}} <br />
      </ng-container>
    </span>
  </div>
  <table class="BlackText" cellpadding="4" cellspacing="0" style="width: 80%; margin-top: 1px;">
    <tbody>
      <tr class="Header outage-info" style="text-align: center;">
        <th style="width: 20%;">Organization</th>
        <th>Company</th>
        <th>Unit</th>
        <th>Unit Type</th>
        <th>DLN</th>
        <th>Start Date</th>
        <th>End Date</th>
      </tr>
      <tr style="text-align: center;">
        <td>{{ outageInfo.description }}</td>
        <td>{{ outageInfo.companyName }}</td>

        <td> <a href="javascript:void(0);" (click)="ShowUnitInfoDialog()">{{outageInfo.unit}}</a></td>
        <td>{{ outageInfo.frameType }}</td>
        <td>{{ outageInfo.dln ? 'Y' : 'N'}}</td>
        <td>
          <mat-form-field appearance="outline" class="OutagesEntry">
            <input matInput [matDatepicker]="startpicker" (dateChange)="OnStartDateChange($event)"
              placeholder="mm/dd/yyyy" [(ngModel)]="outageInfo.outageDate">
            <mat-datepicker-toggle matIconSuffix [for]="startpicker"></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field appearance="outline" class="OutagesEntry">
            <input matInput [matDatepicker]="endpicker" (dateChange)="OnEndDateChange($event)" placeholder="mm/dd/yyyy"
              [(ngModel)]="outageInfo.outageEndDate">
            <mat-datepicker-toggle matIconSuffix [for]="endpicker"></mat-datepicker-toggle>
            <mat-datepicker #endpicker></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="BlackText" cellpadding="4" cellspacing="0" style="width: 80%; margin-top: 10px;">
    <tbody>
      <tr class="Header outage-info" style="text-align: center;">
        <th>Type</th>
        <th>Unit Hrs</th>
        <th>Peak Fired Hrs</th>
        <th>Project</th>
        <th>Duration(Days)</th>
        <th>Turnaround Name</th>
      </tr>
      <tr style="text-align: center;">
        <td style="width: 29%;">
          <mat-form-field appearance="outline" class="OutagesEntry">
            <mat-select [(value)]="outageInfo.outageType" (selectionChange)="onOutageTypeChange($event)">
              <mat-option *ngFor="let row of outageTypes" [value]="row.code">
                {{ row.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td style="width: 10%;">
          <input type="number" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
            [(ngModel)]="outageInfo.unitHours">
        </td>
        <td style="width: 10%;"><input type="number" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
            [(ngModel)]="outageInfo.peakFiredHours"></td>
        <td style="width: 10%;"><input type="text" maxlength="10" style=" margin-top: 5px; margin-left: 15px;"
            class="BlackText" [(ngModel)]="outageInfo.projectCode"></td>
        <td style="width: 10%;"><input type="text" maxlength="3" style="margin-top: 5px; margin-left: 15px;"
            class="BlackText" (change)="onDurationChange(outageInfo.duration, outageInfo.outageDate)"
            [(ngModel)]="outageInfo.duration"></td>
        <td>
          <input type="text" readonly maxlength="50" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
            [(ngModel)]="outageInfo.turnaroundName">
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row tabbed tabbed-content-control centered" style="margin-top: 10px;">
    <div class="filter-bar tab-filter-bar">
      <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

        <mat-tab label="Sets">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <table class="BlackText">
                <tbody>
                  <tr>
                    <td>Legend:&nbsp;&nbsp;</td>
                    <td width="10" style="background-color:Red;"><br></td>
                    <td style="padding-left: 5px;">Max Hours Exceed</td>
                  </tr>
                </tbody>
              </table>

              <table class="BlackText Border" cellspacing="0" cellpadding="4"
                style="border-collapse:collapse; width: 90%;">
                <tbody>
                  <tr class="Header outage-info" style="text-align: center;">
                    <th colspan="2"></th>
                    <th>Part/Set</th>
                    <th colspan="5" style="border-bottom:1px solid white">Part/Set Out Information</th>
                  </tr>
                  <tr class="Header outage-info" style="text-align: center;">
                    <th align="center">Component</th>
                    <th style="width:10px;"><br></th>
                    <th align="center">In</th>
                    <th align="center">Out</th>
                    <th align="center">Life Time Hrs</th>
                    <th align="center">Interval Hrs</th>
                    <th align="center">Scrapped</th>
                    <th align="center">Repaired</th>
                  </tr>

                  <tr class="SubHeader" *ngIf="compressor.length > 0">
                    <th colspan="8">Compressor</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of compressor let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.availableCompressorSets; else noSetsTemplate">
                        <mat-select [(value)]="set.setNumber">
                          <mat-option *ngFor="let availableSet of set.availableCompressorSets"
                            [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                        </mat-select>
                      </ng-container>
                      <ng-template #noSetsTemplate>
                        <label>No Available Sets</label>
                      </ng-template>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <!-- {{ set.repairDate | date: 'MM/dd/yyyy'}} -->
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="combustion.length > 0">
                    <th colspan="8">Combustion</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of combustion let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.availableCombustionSets; else noSetsTemplate">
                        <mat-select [(value)]="set.setNumber">
                          <mat-option *ngFor="let availableSet of set.availableCombustionSets"
                            [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                        </mat-select>
                      </ng-container>
                      <ng-template #noSetsTemplate>
                        <label>No Available Sets</label>
                      </ng-template>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="turbine.length > 0">
                    <th colspan="8">Turbine</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of turbine let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableTurbineSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onScrapSet(set)"
                          value="Scrap">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="generator.length > 0">
                    <th colspan="8">Generator</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of generator let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableGeneratorSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="gearBox.length > 0">
                    <th colspan="8">Gear Box</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of gearBox let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableGearBoxSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="engine.length > 0">
                    <th colspan="8">Engine</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of engine let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableEngineSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="gasRemovalSystem.length > 0">
                    <th colspan="8">Gas Removal System</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of gasRemovalSystem let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableGasRemovalSystemSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="coolingSystem.length > 0">
                    <th colspan="8">Cooling System</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of coolingSystem let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableCoolingSystemSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="highPressureTurbine.length > 0">
                    <th colspan="8">High Pressure Turbine</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of highPressureTurbine let i = index"
                    style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableHighPressureTurbineSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="lowPressureTurbine.length > 0">
                    <th colspan="8">Low Pressure Turbine</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of lowPressureTurbine let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableLowPressureTurbineSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                  <tr class="SubHeader" *ngIf="catalyst.length > 0">
                    <th colspan="8">Catalyst</th>
                  </tr>
                  <tr class="Row1 BlackText" *ngFor="let set of catalyst let i = index" style="color: black;">
                    <td>
                      <span>{{set.componentNameNew}}</span>
                    </td>
                    <td style="width:10px;">
                      <br>
                    </td>
                    <td align="center">
                      <mat-select [(value)]="set.setNumber">
                        <mat-option *ngFor="let availableSet of set.availableCatalystSets"
                          [value]="availableSet.setNumber">{{availableSet.description}}</mat-option>
                      </mat-select>
                    </td>
                    <td align="center">
                      <a href="javascript:void(0);" (click)="onSetInOutClick(set)">{{set.setOut}}</a>
                    </td>
                    <td align="center" [ngClass]="{'PartHoursExceeded': set.maxHours > 0 &&
                          set.lifeTimeHours > set.maxHours}">
                      {{ set.lifeTimeHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      {{ set.intervalHours | number: '1.0-0'}}
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.scrapDate;else scrapButton">
                        {{ set.scrapDate | date: 'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-template #scrapButton>
                        <input style="margin: 5px 0" type="button" (click)="onScrapSet(set)" value="Scrap"
                          class="BlackText">
                      </ng-template>
                    </td>
                    <td align="center">
                      <ng-container *ngIf="set.remainingRepairs ===0">
                        <span class="max-repair-msg">Max Repairs Reached</span>
                      </ng-container>
                      <ng-container *ngIf="canShowRepairButton(set)">
                        <input style="margin: 5px 0" type="button" class="BlackText" (click)="onRepairBtnClick(set)"
                          value="Repair">
                      </ng-container>
                      <ng-container *ngIf="set.repairDate && set.repairDate?.length > 0">
                        <a href="javascript:void(0);" (click)="onShowRepairClick(set)">Show Repair</a>
                      </ng-container>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Meters">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <table class="BlackText" cellpadding="4" cellspacing="0">
                <tbody>
                  <tr class="Row1">
                    <th align="left">Manual Starts:</th>
                    <td align="center"><input type="number" style="margin-top: 5px; margin-left: 15px;"
                        class="BlackText" [(ngModel)]="outageInfo.manualStarts" step="1"
                        (keypress)="preventDecimal($event)"></td>
                  </tr>
                  <tr class="Row2">
                    <th align="left">Total Starts:</th>
                    <td align="center"><input type="number" style="margin-top: 5px; margin-left: 15px;"
                        class="BlackText" [(ngModel)]="outageInfo.totalStarts" step="1"
                        (keypress)="preventDecimal($event)"></td>
                  </tr>
                  <tr class="Row1">
                    <th align="left">Fired Starts:</th>
                    <td align="center"><input type="number" style="margin-top: 5px; margin-left: 15px;"
                        class="BlackText" [(ngModel)]="outageInfo.firedStarts" step="1"
                        (keypress)="preventDecimal($event)"></td>
                  </tr>
                  <tr class="Row2">
                    <th align="left">Emergency Stops:</th>
                    <td align="center"><input type="number" style="margin-top: 5px; margin-left: 15px;"
                        class="BlackText" [(ngModel)]="outageInfo.emergencyStops" step="1"
                        (keypress)="preventDecimal($event)"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="People">
          <ng-template matTabContent>
            <!-- <div style="margin-top: 25px;"> -->
            <table class="BlackText" cellpadding="4" cellspacing="0">
              <tbody>
                <tr class="Row1">
                  <th align="left">Engineer(s):</th>
                  <td><input type="text" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
                      [(ngModel)]="outageInfo.engineer"></td>
                </tr>
                <tr class="Row2">
                  <th align="left">Plant Supervisor:</th>
                  <td><input type="text" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
                      [(ngModel)]="outageInfo.plantSupervisor"></td>
                </tr>
                <tr class="Row1">
                  <th align="left">Contractor:</th>
                  <td><input type="text" style="margin-top: 5px; margin-left: 15px;" class="BlackText"
                      [(ngModel)]="outageInfo.contractor"></td>
                </tr>
              </tbody>
            </table>
            <!-- </div> -->
          </ng-template>
        </mat-tab>

        <mat-tab label="Comments">
          <ng-template matTabContent>
            <div style="margin-top: 15px;">
              <table class="BlackText" cellpadding="4" cellspacing="0">
                <tbody>
                  <tr>
                    <td valign="top">Comments:</td>
                    <td>
                      <textarea rows="10" cols="145" class="BlackText" [(ngModel)]="outageInfo.comments"
                        style="margin-left: 7px;">
                                  </textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Links ({{ links.length}})">
          <ng-template matTabContent>

            <div class="ErrorMsgArea" *ngIf="errorMessage">
              <span class="errormsg"> {{ errorMessage }} </span>
            </div>

            <div style="margin-top: 15px;">
              <table cellpadding="4" cellspacing="0" class="BlackText Border">
                <tbody>
                  <tr class="Header">
                    <th style="background-color: #009dd0; text-align: center;">Link</th>
                    <th></th>
                  </tr>
                  <tr class="Row1">
                    <td style="border-bottom: 1px solid black">
                      <div style="margin-top: 7px;">
                        Description: <input type="text" maxlength="100" class="BlackText" size="100"
                          [(ngModel)]="description">
                      </div>
                      <div style="margin-top: 7px; margin-bottom: 7px;">
                        Link: <mat-form-field appearance="outline" style="height: 20px;">
                          <mat-select [(value)]="linkSelected" (selectionChange)="onLinkChange($event)">
                            <mat-option *ngFor="let row of dynamicLinks" [value]="row.name">
                              {{ row.dynamicLink }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field> <input type="text" maxlength="1000" class="BlackText" size="80"
                          style="margin-left: 10px;" [(ngModel)]="linkDescription">
                      </div>
                    </td>
                    <td style="width: 1%;">
                    </td>
                    <td style="border-bottom: 1px solid black">
                      <input type="submit" value="Insert" class="BlackText" (click)="InsertLink()">
                      <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                        (click)="onAddClickCancel()">
                      <br>
                      <input type="button" value="Document Repository" style="margin-top: 15px;"
                        onclick="window.open('https://collab001-hou.sp.chevron.net/sites/cgpmpe/partstracking/default.aspx','_blank');">
                    </td>
                  </tr>
                  <tr style="text-align: left;" *ngFor="let linkItem of links">
                    <td style="display:none;">
                      {{ linkItem.recordNumber }}
                    </td>
                    <td>
                      <div>
                        <a [href]="linkItem.link" target="_blank">
                          {{ linkItem.description }}
                        </a>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <input type="submit" value="Edit" class="BlackText" style="margin-top: 5px;"
                        (click)="EditLink(linkItem.recordNumber)">
                      <input type="submit" value="Delete" class="BlackText" style="margin-left: 3px;"
                        (click)="DeleteLink(linkItem.recordNumber)">
                    </td>
                  </tr>

                  <!-- Update UI -->
                  <tr class="Row2" *ngIf="isEdit">
                    <td>
                      Descripton: <input maxlength="100" class="BlackText" size="100"
                        [(ngModel)]="updateLink.description"><br>
                      <div style="margin-top: 7px; margin-bottom: 7px;">
                        Link: <mat-form-field appearance="outline" style="height: 20px;">
                          <mat-select [(value)]="selectedLinkUpdate" (selectionChange)="onLinkUpdateChange($event)">
                            <mat-option *ngFor="let row of dynamicLinks" [value]="row.name">
                              {{ row.dynamicLink }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <input type="text" maxlength="1000" class="BlackText" size="80" style="margin-left: 10px;"
                          [(ngModel)]="updateLink.link">
                      </div>
                    </td>
                    <td style="width: 12% !important;">
                      <input type="submit" value="Update" class="BlackText" style="margin-left: 5px;"
                        (click)="UpdateLink()">
                      <input type="submit" value="Cancel" class="BlackText" style="margin-left: 5px;"
                        (click)="onClickCancel()">
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <br>
    <div style="margin-top: 15px;">
      <input style="margin-right: 5px;" type="submit" (click)="PullPIData()" value="Pull PI Data" class="BlackText">
      <input type="submit" value="Update" (click)="UpdateOutage()" class="BlackText">
      <a href="/tops/tops-home"><input type="button" class="BlackText" value="Cancel"
          style="margin-left: 5px;margin-right: 5px;"></a>
      <input style="margin-right: 5px;" type="submit" value="Delete" (click)="DeleteOutage()" class="BlackText">
      <input style="margin-right: 5px;" type="submit" value="Parts Status Report" (click)="PartStatus()"
        class="BlackText">
      <a href="https://chevron.sharepoint.com/sites/cgpmpe/SitePages/Active-Turnarounds-and-Gas-Turbine-Lifecycle.aspx"
        target="_blank"><input type="button" class="BlackText" value="Open Active Turnarounds"
          style="margin-left: 1px;margin-right: 5px;"></a>
    </div>
  </div>
</div>